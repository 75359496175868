import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function AccountLocations() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {
      setAccessType('Edit');
    }
    else {
      console.log('competitor',foundUser)
      setAccessType(foundUser.access['AccountLocations_Access Specifier']);
    }

  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h6' sx={{ pb: 3 }} >
           <b>Account-Locations</b> {accessType === 'View' ? " / View Only Mode" : ""}
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          {/* <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button> */}
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit Adjustment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
      
        </div> */}


      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
      

    </>
  );
}