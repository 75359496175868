import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from "react-select";
import TimezoneSelect from 'react-timezone-select'
import { baseURL } from '../backend_url';
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css';
import CurrencyList from 'currency-list'
import { Modal } from 'react-bootstrap'
import { MDBDataTable } from 'mdbreact';
import './location.css';
import Swal from 'sweetalert2';

export default function UpdateForm({ accessType }) {

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [companyID, setcompanyID] = useState("");

  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");

  const [street1, setStreet1] = useState('')
  const [street2, setStreet2] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [contactName, setContactName] = useState('')
  const [email, setEmail] = useState('')
  const [phone1, setPhone1] = useState('')
  const [phone2, setPhone2] = useState('')
  const [rateFlow, setRateFlow] = useState('Pickup date based')
  const [netDayCutOff, setNetDayCutOff] = useState('')
  const [distanceUnit, setDistanceUnit] = useState('')
  const [currency, setCurrency] = useState('')
  const [daysInMonth, setDaysInMonth] = useState(30)
  const [locationType, setLocationType] = useState('')

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({});

  const [latLongFormat, setLatLongFormat] = useState('Decimal');
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [latDeg, setLatDeg] = useState('')
  const [latMins, setLatMins] = useState('')
  const [latSecs, setLatSecs] = useState('')
  const [longDeg, setLongDeg] = useState('')
  const [longMins, setLongMins] = useState('')
  const [longSecs, setLongSecs] = useState('')

  const [viewMapFlag, setViewMapFlag] = useState(false);

  const [weekendStartFromSelected, setweekendStartFromSelected] = useState('Friday');
  const [noOfDaysInWeekendSelected, setnoOfDaysInWeekendSelected] = useState(2);
  const [weekendRatesShowFlag, setWeekendRatesShowFlag] = useState(false);
  const [lengthOfWeekSelected, setLengthOfWeekSelected] = useState(7);
  const [advBooking, setAdvBooking] = useState(0);

  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [locationSummaryData, setLocationSummaryData] = useState({});
  const [modalShowFlag, setModalShowFlag] = useState(false)


  // API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {
      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Locations_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);

  // Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {
    if (companyDetail && companyName) {
      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      setcompanyID(temp_company_id);

      let company_type = obj_company_detail.account_type;
      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(data.res);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                }

              }
              )
          }
        });
    }

    setLocationDetail([])
    setLocationName('');
    setLocationId('');

    setStreet1('');
    setStreet2('');
    setZipcode('');
    setCountry('');
    setState('');
    setCity('');

    setContactName('')
    setEmail('');
    setPhone1('')
    setPhone2('');
    setSelectedTimezone('');
    setRateFlow('Pickup date based')
    setNetDayCutOff('');

    setDistanceUnit('');
    setCurrency('');

    setDaysInMonth('30');
    setLocationType('');

    setLatLongFormat('Decimal');
    setLatitude('');
    setLatDeg('');
    setLatMins('');
    setLatSecs('');

    setLongitude('');
    setLongDeg('');
    setLongMins('');
    setLongSecs('');

    setViewMapFlag(false);

    setweekendStartFromSelected('Friday');
    setnoOfDaysInWeekendSelected('2');
    setWeekendRatesShowFlag(false);
    setLengthOfWeekSelected('7');
    setAdvBooking("0");
  }, [companyName]);


  useEffect(() => {
    let temp_location_id = ''
    if (locationName && locationDetail) {
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      // fetch all the details from the DB
      fetch(baseURL + "/get_location_details/" + temp_location_id.toString())
        .then((res) => res.json())
        .then((data) => {
          if (data === null) {
            setStreet1('');
            setStreet2('');
            setZipcode('');
            setCountry('');
            setState('');
            setCity('');

            setContactName('')
            setEmail('');
            setPhone1('')
            setPhone2('');
            setSelectedTimezone('');
            setRateFlow('Pickup date based');
            setNetDayCutOff('');

            setDistanceUnit('');
            setCurrency('');

            setDaysInMonth('30');
            setLocationType('');

            setLatLongFormat('Decimal');
            setLatitude('');
            setLatDeg('');
            setLatMins('');
            setLatSecs('');

            setLongitude('');
            setLongDeg('');
            setLongMins('');
            setLongSecs('');

            setViewMapFlag(false);

            setweekendStartFromSelected('Friday');
            setnoOfDaysInWeekendSelected('2');
            setWeekendRatesShowFlag(false);
            setLengthOfWeekSelected('7');
            setAdvBooking(0);

            // Yup validations
            setValue('email', '')
          }
          else {
            if (data.item != null) {
              setStreet1(data.item.street1 ?? '')
              setStreet2(data.item.street2 ?? '')
              setZipcode(data.item.zip ?? '')

              setContactName(data.item.contact_name ?? '')
              setEmail(data.item.email ?? '')
              setPhone1(data.item.phone1 ?? '')
              setPhone2(data.item.phone2 ?? '')

              setSelectedTimezone(data.item.timezone ?? '')
              setRateFlow(data.item.rate_flow)
              setNetDayCutOff(data.item.net_day_cutoff ?? '')

              setDistanceUnit(data.item.distance_unit ?? '')
              setCurrency(data.item.currency ?? '')

              setDaysInMonth(data.item.days_in_month)

              setCountry(data.item.country)
              setState(data.item.state)
              setCity(data.item.city)
              setLocationType(data.item.location_type)

              setLatLongFormat("Decimal");
              setLatitude(data.item.latitude ?? '')
              setLatDeg(data.item.lat_deg ?? '')
              setLatMins(data.item.lat_min ?? '')
              setLatSecs(data.item.lat_sec ?? '')

              setLongitude(data.item.longitude ?? '')
              setLongDeg(data.item.long_deg ?? '')
              setLongMins(data.item.long_min ?? '')
              setLongSecs(data.item.long_sec ?? '')

              setweekendStartFromSelected(data.item.weekend_start_from)
              setnoOfDaysInWeekendSelected(data.item.no_of_days_in_weekend)

              setWeekendRatesShowFlag(data.item.weekend_rates_to_be_shown);

              setLengthOfWeekSelected(data.item.length_of_week)
              setAdvBooking(data.item.adv_booking ?? 0);

              // set values for Yup
              setValue("email", data.item.email ?? '')
            }
            else {
              setStreet1('');
              setStreet2('');
              setZipcode('');

              setContactName('')
              setEmail('');
              setPhone1('')
              setPhone2('');
              setSelectedTimezone('');
              setRateFlow('Pickup date based');
              setNetDayCutOff('');

              setDistanceUnit('');
              setCurrency('');

              setDaysInMonth('30');
              setLocationType('');

              setLatLongFormat('Decimal');
              setLatitude('');
              setLatDeg('');
              setLatMins('');
              setLatSecs('');

              setLongitude('');
              setLongDeg('');
              setLongMins('');
              setLongSecs('');

              setViewMapFlag(false);

              setweekendStartFromSelected('Friday');
              setnoOfDaysInWeekendSelected('2');
              setWeekendRatesShowFlag(false);
              setLengthOfWeekSelected('7');
              setAdvBooking(0);

              // Yup validations
              setValue('email', '')
            }

            if (data.loc_item != null) {
              setCountry(data.loc_item.country)
              setState(data.loc_item.state)
              setCity(data.loc_item.city)
              setLocationType(data.loc_item.location_type)
            }
            else {
              setCountry('')
              setState('')
              setCity('')
              setLocationType('')
            }
          }
        })
    }
  }, [locationName])

  const changeLatitudeValue = (val) => {
    setLatitude(val);

    if (val != '' && val != 'e' && val != '-') {
      let temp = parseFloat(val);
      let deg = parseInt(temp);
      let t = (temp - deg) * 60;
      let min = parseInt(t);
      let t2 = (t - min) * 60;
      let sec = parseInt(Math.round(t2));

      setLatDeg(deg);
      setLatMins(min);
      setLatSecs(sec);
    }
    else {
      setLatDeg('');
      setLatMins('');
      setLatSecs('');
    }
  }

  const changeLongitudeValue = (val) => {
    setLongitude(val);

    if (val != '' && val != 'e' && val != '-') {
      let temp = parseFloat(val);
      let deg = parseInt(temp);
      let t = (temp - deg) * 60;
      let min = parseInt(t);
      let t2 = (t - min) * 60;
      let sec = parseInt(Math.round(t2));

      setLongDeg(deg);
      setLongMins(min);
      setLongSecs(sec);
    }
    else {
      setLongDeg('');
      setLongMins('');
      setLongSecs('');
    }
  }

  const changeLatDeg = (val) => {
    setLatDeg(val);

    if (val != '' && latMins != '' && latSecs != '') {
      let temp = parseInt(val) + (parseInt(latMins) / 60) + (parseFloat(latSecs) / 3600)

      setLatitude(temp);
    }
    else {
      setLatitude('');
    }
  }

  const changeLatMin = (val) => {
    setLatMins(val);

    if (latDeg != '' && val != '' && latSecs != '') {
      let temp = parseInt(latDeg) + (parseInt(val) / 60) + (parseFloat(latSecs) / 3600)

      setLatitude(temp);
    }
    else {
      setLatitude('');
    }
  }

  const changeLatSec = (val) => {
    setLatSecs(val);

    if (latDeg != '' && latMins != '' && val != '') {
      let temp = parseInt(latDeg) + (parseInt(latMins) / 60) + (parseFloat(val) / 3600)

      setLatitude(temp);
    }
    else {
      setLatitude('');
    }
  }

  const changeLongDeg = (val) => {
    setLongDeg(val);

    if (val != '' && longMins != '' && longSecs != '') {
      let temp = parseInt(val) + (parseInt(longMins) / 60) + (parseFloat(longSecs) / 3600)

      setLongitude(temp);
    }
    else {
      setLongitude('');
    }
  }

  const changeLongMin = (val) => {
    setLongMins(val);

    if (longDeg != '' && val != '' && longSecs != '') {
      let temp = parseInt(longDeg) + (parseInt(val) / 60) + (parseFloat(longSecs) / 3600)

      setLongitude(temp);
    }
    else {
      setLongitude('');
    }
  }

  const changeLongSec = (val) => {
    setLongSecs(val);

    if (longDeg != '' && longMins != '' && val != '') {
      let temp = parseInt(longDeg) + (parseInt(longMins) / 60) + (parseFloat(val) / 3600)

      setLongitude(temp);
    }
    else {
      setLongitude('');
    }
  }


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  const handleInputChangeDistanceUnit = characterEntered => {
    setDistanceUnit(characterEntered.value);
  }

  const handleInputChangeCurrency = characterEntered => {
    setCurrency(characterEntered.value);
  }

  const handleInputChangeDaysInMonth = characterEntered => {
    setDaysInMonth(characterEntered.value);
  }

  const handleInputChangeWeekendStartFrom = characterEntered => {
    setweekendStartFromSelected(characterEntered.value);
  }

  const handleInputChangeNoOfDaysInWeekend = characterEntered => {
    setnoOfDaysInWeekendSelected(characterEntered.value);
  }

  const handleInputChangeLatLongFormat = characterEntered => {
    setLatLongFormat(characterEntered.value);
  }

  const handleInputChangeLengthOfWeek = characterEntered => {
    setLengthOfWeekSelected(characterEntered.value)
  }

  const handleInputChangeNextDayCutoff = characterEntered => {
    setNetDayCutOff(characterEntered.value)
  }

  const handleInputChangeRateFlow = characterEntered => {
    setRateFlow(characterEntered.value)
  }

  const currencyOptions = Object.keys(CurrencyList.getAll('en_US')).map((key) => (
    { value: key, label: key }
  ));

  // form validation rules 
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Invalid Email ID'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

  // OnSubmit code
  const submitHandler = () => {
    setSuccessMsg("");
    setLoading(true);
    setValidationMsg("");

    if (companyName === '') {
      setLoading(false)
      window.scrollTo(0, 0);
      setValidationMsg('Please select a company name to proceed!')
    }

    else if (companyName != '' && locationName === '') {
      setLoading(false)
      window.scrollTo(0, 0);
      setValidationMsg('Please select a branch name to proceed!')
    }

    if (selectedTimezone == '') {
      setLoading(false)
      window.scrollTo(0, 0);
      setValidationMsg('Please select Timezone to proceed!')
    }

    else {
      fetch(baseURL + '/save_location_details', {
        method: 'POST', headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          "account_name": companyName.toString(),
          "location_name": locationName.toString(),
          "location_id": locationId.toString(),
          "street1": street1 ? street1 : null,
          "street2": street2 ? street2 : null,
          "zip": zipcode ? zipcode : null,
          "country": country ? country : null,
          "state": state ? state : null,
          "city": city ? city : null,
          "contact_name": contactName,
          "email": email ? email : null,
          "phone1": phone1 ? phone1 : null,
          "phone2": phone2 ? phone2 : null,
          "timezone": selectedTimezone ? selectedTimezone : null,
          "rate_flow": rateFlow,
          "net_day_cutoff": netDayCutOff ? netDayCutOff : null,
          "distance_unit": distanceUnit ? distanceUnit : null,
          "currency": currency ? currency : null,
          "days_in_month": daysInMonth ? daysInMonth : null,
          "location_type": locationType ? locationType : null,
          "lat_long_format": latLongFormat,
          "latitude": latitude !== null && latitude !== '' ? latitude : null,
          "lat_deg": latDeg !== null && latDeg !== '' ? latDeg : null,
          "lat_min": latMins !== null && latMins !== '' ? latMins : null,
          "lat_sec": latSecs !== null && latSecs !== '' ? latSecs : null,
          "longitude": longitude !== null && longitude !== '' ? longitude : null,
          "long_deg": longDeg !== null && longDeg !== '' ? longDeg : null,
          "long_min": longMins !== null && longMins !== '' ? longMins : null,
          "long_sec": longSecs !== null && longSecs !== '' ? longSecs : null,
          "weekend_start_from": weekendStartFromSelected,
          "no_of_days_in_weekend": noOfDaysInWeekendSelected,
          "weekend_rates_to_be_shown": weekendRatesShowFlag,
          "length_of_week": lengthOfWeekSelected,
          "adv_booking": advBooking,
          "updated_by": loggedInUserDetail.userEmail
        })
      })
        .then(resp => resp.json())
        .then(data => {
          setLoading(false)
          // setSuccessMsg("Location Setup successful!")
          Swal.fire("Location Setup successful!")

          window.scrollTo(0, 0);
        })
        .catch(error => console.log(error))

      setCompanyName('')
      setcompanyID('')
      setLocationName('');
      setLocationId('');
      setLocationDetail([{}]);

      setStreet1('');
      setStreet2('');
      setZipcode('');
      setCountry('');
      setState('');
      setCity('');

      setContactName('')
      setEmail('');
      setPhone1('')
      setPhone2('');
      setSelectedTimezone('');
      setRateFlow('Pickup date based');
      setNetDayCutOff('');

      setDistanceUnit('');
      setCurrency('');

      setDaysInMonth('30');
      setLocationType('');

      setLatLongFormat('Decimal');
      setLatitude('');
      setLatDeg('');
      setLatMins('');
      setLatSecs('');

      setLongitude('');
      setLongDeg('');
      setLongMins('');
      setLongSecs('');

      setViewMapFlag(false);

      setweekendStartFromSelected('Friday');
      setnoOfDaysInWeekendSelected('2');
      setWeekendRatesShowFlag(false);
      setLengthOfWeekSelected('7');
      setAdvBooking(0);

      // Yup Validation setValue
      // setting value for Yup validations
      setValue('email', '')
    }
  }

  const showLocationSummary = () => {
    setModalShowFlag(true);

    fetch(baseURL + `/get_location_summary/${companyID}`)
      .then(resp => resp.json())
      .then((data) => {

        if (data.length > 0) {
          let column_data = [
            {
              label: 'Location Name',
              field: 'location_name',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Address',
              field: 'address',
              sort: 'asc',
              width: 250
            },
            {
              label: 'Contact Person',
              field: 'contact_name',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Email',
              field: 'email',
              sort: 'asc',
              width: 350
            },
            {
              label: 'Phone Number',
              field: 'phone',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Rate Flow',
              field: 'rate_flow',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Timezone',
              field: 'timezone',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Next Day Cutoff',
              field: 'net_day_cutoff',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Distance Unit',
              field: 'distance_unit',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Currency',
              field: 'currency',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Days in Month',
              field: 'days_in_month',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Location Type',
              field: 'location_type',
              sort: 'asc',
              width: 100
            },
            {
              label: 'Co-ordinates',
              field: 'coordinates',
              sort: 'asc',
              width: 100
            }
          ]

          let rows_data = [];

          for (var i = 0; i < data.length; i++) {
            let rowItem = {};
            rowItem["location_name"] = data[i]['location_name']
            rowItem["address"] = "Street 1 - " + data[i]["street1"] + ", Street 2 - " + data[i]["street2"]
              + ", City - " + data[i]['city'] + ", State - " + data[i]['state']
              + ", Country - " + data[i]['country'] + ", ZipCode - " + data[i]['zip']
            rowItem["contact_name"] = data[i]["contact_name"]
            rowItem["email"] = data[i]["email"]
            rowItem["email"] = data[i]["email"]
            rowItem["phone"] = "Phone 1 - " + data[i]["phone1"] + ", Phone 2 - " + data[i]['phone2']
            rowItem["rate_flow"] = data[i]['rate_flow']
            rowItem["timezone"] = data[i]['timezone'] !== null ? data[i]['timezone']['label'] : null
            rowItem["net_day_cutoff"] = data[i]['net_day_cutoff']
            rowItem["distance_unit"] = data[i]['distance_unit']
            rowItem["currency"] = data[i]['currency']
            rowItem["days_in_month"] = data[i]['days_in_month']
            rowItem["location_type"] = data[i]['location_type']
            rowItem["coordinates"] = "Latitude - " + data[i]['latitude'] + ", Longitutde - " + data[i]['longitude']
            rowItem["weekend_start_from"] = data[i]['weekend_start_from']
            rowItem["no_of_days_weekend"] = data[i]['no_of_days_in_weekend']
            rowItem["weekend_rate_to_be_shown"] = data[i]['weekend_rates_to_be_shown'] === true ? "True" : "False"
            rowItem["length_of_week"] = data[i]['length_of_week']
            rowItem["advance_booking"] = data[i]['adv_booking']

            rows_data.push(rowItem)
          }

          // Table Data
          const tableData = {
            columns: column_data,
            rows: rows_data
          };

          setLocationSummaryData(tableData);
        }
        else {
          setLocationSummaryData({})
        }
      })
  }

  return (
    <>
      <Modal
        show={modalShowFlag}
        onHide={() => setModalShowFlag(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton style={{ color: 'white', backgroundColor: '#0dcaf0' }}>
          <Modal.Title id="example-custom-modal-styling-title" >
            Location Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            Object.keys(locationSummaryData).length > 0 ?
              <MDBDataTable
                bordered
                large={true}
                data={locationSummaryData}
                sortable={true}
                responsiveLg={true}
                noBottomColumns={true}
                entries={5}
                className='cust-table'
                entriesOptions={[5, 10, 20, 50, 100]}
                btn
                responsive
              />
              :
              <h6 htmlFor="dataNotFound" className="form-label" style={{ color: 'red' }}>
                <strong>No Data found!</strong>
              </h6>
          }
        </Modal.Body>
      </Modal>


      <div className="row pt-1">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            {accessType === "View" ? "Select account & location to view details: " : "Select account & location to add/edit details:"}
          </span>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-10 mx-3">
          <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
            {
              validationMsg ?
                (<Alert severity="error">{validationMsg}!</Alert>) : ""
            }

            {
              successMsg ?
                (
                  <div className="alert alert-success alert-dismissible fade show" role="alert" >
                    {successMsg}
                      <button
                      type="button"
                      onClick={() => setSuccessMsg('')}
                      aria-label="Close"
                    >
                      &times;
                    </button>
                  </div>
                )
                : ""
            }

            <div className="row pt-3">
              <div className="col-md-2 mb-3">
                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                  Account Name
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <Select
                  value={{ value: companyName, label: (companyName ? companyName : "Select Account name") }}
                  inputId="companyName"
                  name="companyName"
                  options={companyNameOptions}
                  onChange={handleInputChangeCompanyName}
                />
              </div>

              {
                companyName !== '' ?
                  <>
                    <div className="col-md-1 mb-3"></div>

                    <div className="col-md-3 mb-3">
                      <button className='btn btn-info' type='button' onClick={showLocationSummary} style={{ height: "fit-content", width: "100%" }}>
                        View Location Summary
                      </button>
                    </div>
                  </>
                  : ''
              }
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="location_name" className="form-label" style={{ marginTop: 5 }}>
                  Location Name
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <Select
                  value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                  inputId="locationName"
                  name="locationName"
                  options={locationNameOptions}
                  onChange={handleInputChangeLocationName}
                />
              </div>

              {
                locationName !== '' ?
                  <>
                    <div className="col-md-1 mb-3"></div>

                    <div className="col-md-1 mb-3">
                      <label htmlFor="GDScode" className="form-label" style={{ marginTop: 7 }}>
                        GDS Code
                      </label>
                    </div>

                    <div className="col-md-2 mb-3">
                      <input type="text" className="form-control" id="GDScode" value={locationId.toString().split("-")[1]}
                        disabled={true} />
                    </div>
                  </>
                  :
                  ''
              }
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="street1" className="form-label" style={{ marginTop: 5 }}>
                  Street 1
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <textarea
                  className='form-control'
                  id="street1"
                  value={street1}
                  onChange={(e) => setStreet1(e.target.value)}
                  disabled={accessType === "View"} />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="street2" className="form-label" style={{ marginTop: 5 }}>
                  Street 2
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <textarea
                  className='form-control'
                  id="street2"
                  value={street2}
                  onChange={(e) => setStreet2(e.target.value)}
                  disabled={accessType === "View"} />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="zipCode" className="form-label" style={{ marginTop: 5 }}>
                  Zip Code
                </label>
              </div>

              <div className="col-md-2 mb-3">
                <input type="text" className="form-control" id="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)}
                  disabled={accessType === "View"}
                />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="country" className="form-label" style={{ marginTop: 5 }}>
                  Country
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <input type="text" className="form-control" id="country" value={country} disabled />
              </div>

              <div className="col-md-1 mb-3">
                <label htmlFor="state" className="form-label" style={{ marginTop: 5 }}>
                  State
                </label>
              </div>

              <div className="col-md-2 mb-3">
                <input type="text" className="form-control" id="state" value={state} disabled />
              </div>

              <div className="col-md-1 mb-3">
                <label htmlFor="city" className="form-label" style={{ marginTop: 5 }}>
                  City
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <input type="text" className="form-control" id="city" value={city} disabled />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="contactName" className="form-label">
                  Location Contact Person Name
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <input type="text" className="form-control" id="contactName" value={contactName} onChange={(e) => setContactName(e.target.value)}
                  disabled={accessType === "View"}
                />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="email" className="form-label" style={{ marginTop: 5 }}>
                  Email Address
                </label>
              </div>

              <div className="col-md-4 mb-3">
                <input type="text" className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  {...register('email')} id="email" value={email} onChange={(e) => setEmail(e.target.value)}
                  disabled={accessType === "View"}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="phone1" className="form-label" style={{ marginTop: 5 }}>
                  Phone - 1
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={phone1} placeholder='' onChange={(value) => setPhone1(value)} />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="phone2" className="form-label" style={{ marginTop: 5 }}>
                  Phone - 2
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={phone2} placeholder='' onChange={(value) => setPhone2(value)} />
              </div>

              <div className="col-md-2 mb-3"></div>
            </div>


            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="timezone" className="form-label" style={{ marginTop: 5 }}>
                  Timezone
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  isDisabled={accessType === "View"}
                />
              </div>
              <div className="col-md-2 mb-3"></div>
              <div className="col-md-2 mb-3">
                <label htmlFor="currency" className="form-label" style={{ marginTop: 5 }}>
                  Currency
                </label>
              </div>
              
              <div className="col-md-3 mb-3">
                <Select
                  value={{ value: currency, label: (currency ? currency : 'Select Currency') }}
                  options={currencyOptions}
                  onChange={handleInputChangeCurrency}
                  isDisabled={accessType === "View"}
                />
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="daysInMonth" className="form-label" style={{ marginTop: 5 }}>
                  Days in Month
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <Select
                  value={{ value: daysInMonth, label: daysInMonth }}
                  inputId="daysInMonth"
                  name="daysInMonth"
                  options={[{ value: '28', label: '28' }, { value: '30', label: '30' }]}
                  onChange={handleInputChangeDaysInMonth}
                  isDisabled={accessType === "View"}
                />
              </div>

              <div className="col-md-2 mb-3"></div>

              <div className="col-md-2 mb-3">
                <label htmlFor="locationType" className="form-label" style={{ marginTop: 5 }}>
                  Location Type
                </label>
              </div>

              <div className="col-md-3 mb-3">
                <input type="text" className="form-control" value={locationType} id="locationType" disabled />
              </div>
            </div>


            <div className="row pt-2">
              <div className="col-md-6 mb-2">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label htmlFor="format" className="form-label">
                      Latitude/Longitude Format
                    </label>
                  </div>

                  <div className="col-md-6 mb-3">
                    <Select
                      value={{ value: latLongFormat, label: latLongFormat }}
                      options={[{ value: "Decimal", label: "Decimal" }, { value: "Degree", label: "Degree" }]}
                      onChange={handleInputChangeLatLongFormat}
                    />
                  </div>
                </div>

                {
                  latLongFormat === 'Decimal' ?
                    <>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="latitute" className="form-label" style={{ marginTop: 5 }}>
                            Latitude
                          </label>
                        </div>

                        <div className="col-md-6 mb-3">
                          <input
                            type="number"
                            min={-90}
                            max={90}
                            step={"any"}
                            className="form-control"
                            value={latitude}
                            id="latitude"
                            onChange={(e) => changeLatitudeValue(e.target.value)}
                            disabled={accessType === "View"}
                          />
                        </div>
                      </div>

                      <div className="row pt-2">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="longitude" className="form-label" style={{ marginTop: 5 }}>
                            Longitude
                          </label>
                        </div>

                        <div className="col-md-6 mb-3">
                          <input
                            type="number"
                            min={-180}
                            max={180}
                            step={"any"}
                            className="form-control"
                            value={longitude}
                            id="longitude"
                            onChange={(e) => changeLongitudeValue(e.target.value)}
                            disabled={accessType === "View"}
                          />
                        </div>
                      </div>

                      <div className="row pt-2">
                        <div className="col-md-6 mb-3"></div>
                        <div className="col-md-4 mb-3 d-flex justify-content-end">
                          <button type="button" className="btn btn-info btn-sm"
                            disabled={latitude === '' || longitude === '' ? true : false}
                            onClick={() => { setViewMapFlag(true) }}>View Map</button>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          <label htmlFor="latitute" className="form-label" style={{ marginTop: 5 }}>
                            Latitude
                          </label>
                        </div>

                        <div className="col-md-3 mb-3">
                          <div className="input-group mb-3">
                            <input type="number" min={-90} max={90} className="form-control" value={latDeg} onChange={(e) => changeLatDeg(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">°</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mb-3">
                          <div className="input-group mb-3">
                            <input type="number" min={-60} max={60} className="form-control" value={latMins} onChange={(e) => changeLatMin(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">'</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mb-3">
                          <div className="input-group mb-3">
                            <input type="text" min={-60} max={60} step={"any"} className="form-control" value={latSecs} onChange={(e) => changeLatSec(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">''</div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <label htmlFor="longitude" className="form-label" style={{ marginTop: 5 }}>
                            Longitude
                          </label>
                        </div>

                        <div className="col-md-3 mb-2">
                          <div className="input-group mb-3">
                            <input type="number" min={-180} max={180} className="form-control" value={longDeg} onChange={(e) => changeLongDeg(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">°</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mb-2">
                          <div className="input-group mb-3">
                            <input type="number" min={-60} max={60} className="form-control" value={longMins} onChange={(e) => changeLongMin(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">'</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mb-2">
                          <div className="input-group mb-3">
                            <input type="text" min={-60} max={60} step={"any"} className="form-control" value={longSecs} onChange={(e) => changeLongSec(e.target.value)} disabled={accessType === "View"} />
                            <div className="input-group-prepend">
                              <div className="input-group-text">''</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8 mb-3"></div>
                        <div className="col-md-4 mb-3 d-flex justify-content-end">
                          <button type="button" className="btn btn-info btn-sm" disabled={latitude === '' || longitude === '' ? true : false} onClick={() => { setViewMapFlag(true) }}>View Map</button>
                        </div>
                      </div>
                    </>
                }
              </div>

              <div className="col-md-6 mb-2">
                {
                  viewMapFlag ?
                    <div className="card h-100">
                      <div className="card-body" style={{ padding: 0 }}>
                        {/* <Map /> */}
                        <iframe src={`https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;&output=embed`} height="100%" width="100%"></iframe>
                      </div>
                    </div>
                    : ''
                }
              </div>
            </div>

            <div className="row pt-3">
              <div className="col mb-4 d-flex justify-content-end">
                {!(accessType === "View") && (loading ? (
                  <button className="btn btn-success" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                    Update
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success" >
                    Update
                  </button>
                ))}
              </div>
            </div>
          </form>
        </div >
      </div >
    </>
  );
}
