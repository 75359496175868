import React, { useState, useEffect } from 'react'
import { Alert, Button, Typography } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Modal } from 'react-bootstrap'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import SaveIcon from '@mui/icons-material/Save';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { MultiSelect } from "react-multi-select-component";
import SettingsIcon from '@mui/icons-material/Settings';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './strategy.css';
// import strategy from './strategy';

export default function UpdateForm({ accessType }) {
    // const modalStyles = {
    //     zIndex: 9999,
    //     width: '100%',  // Adjust the width as needed
    //     maxWidth: '90%',  // Adjust the max width as needed
    // };
    const strategyOptions = [
        'Lowest', 
        '2nd Lowest', 
        '3rd Lowest',
        'Lowest Off-Airport', 
        '2nd Lowest Off-Airport', 
        '3rd Lowest Off-Airport',
        'Lowest In-Terminal', 
        '2nd Lowest In-Terminal', 
        '3rd Lowest In-Terminal',
        'Lowest among all the Car Classes', 
        'Leave as it is'
    ];
    const lorOptions = [
        '1', 
        '2', 
        '3',
        '4', 
        '5', 
        '6',
        '7'
    ];
    const [selectedLorValues, setSelectedLorValues] = useState(Array(7).fill(''));
    const [listOfExistingStrategies, setListOfExistingStrategies] = useState([])



    
    // const handleLorChange = (index, value) => {
    //     const updatedValues = [...selectedLorValues];
    //     updatedValues[index] = value;
    //     setSelectedLorValues(updatedValues);
    // };


    // const handleLorChange = (index, value) => {
    //     setCarData(prevData => {
    //         const updatedCarData = { ...prevData };
    //         if (!updatedCarData[selectedCar].advancedSettings.lorMapping) {
    //             updatedCarData[selectedCar].advancedSettings.lorMapping = {};
    //         }
    //         updatedCarData[selectedCar].advancedSettings.lorMapping[index] = value;
    //         return updatedCarData;
    //     });
    // };

    const handleLorChange = (index, value) => {
        if (!carData[selectedCar]) {
            console.log("Selected car data is undefined:", carData[selectedCar]);
            return;
        }
        if (!carData[selectedCar].advancedSettings) {
            console.log("Advanced settings are undefined:", carData[selectedCar].advancedSettings);
            return;
        }
    
        setCarData(prevData => {
            const selectedCarData = prevData[selectedCar];
            const advancedSettings = selectedCarData.advancedSettings;
    
            const newLorMapping = [...(advancedSettings.lorMapping || Array(7).fill(''))];
            newLorMapping[index] = value;
    
            return {
                ...prevData,
                [selectedCar]: {
                    ...selectedCarData,
                    advancedSettings: {
                        ...advancedSettings,
                        lorMapping: newLorMapping
                    }
                }
            };
        });
    };

    const handleOta = (value) => {
        // console.log("value",value)
        setSelectedOTA(value); 
        if(value == "Expedia"){
            setCategories(expediaOptions)

            setCarData(prevData => {
                const updatedData = {};
                // console.log('prevData1',prevData)
            
                for (const [key, car] of Object.entries(prevData)) {
                    if (car.competitionType === "OTA Assigned Category") {
                        updatedData[key] = {
                            ...car,
                            selectedCategories: [],
                            selectedCategory: ''
                        };
                    } else {
                        updatedData[key] = car;
                    }
                }
            
                return {
                    ...prevData,
                    ...updatedData
                };
            });
            // setCategories(expediaOptions)
            // setCarData(prevData => ({
            //     ...prevData,
            //     [selectedCar]: {
            //         ...prevData[selectedCar],
            //         selectedCategories: [],
            //         selectedCategory:''
            //     }
            // }));
        }else if(value == 'Priceline'){
            setCategories(pricelineOptions)
            setCarData(prevData => {
                const updatedData = {};
                // console.log('prevData2',prevData)
            
                for (const [key, car] of Object.entries(prevData)) {
                    if (car.competitionType === "OTA Assigned Category") {
                        updatedData[key] = {
                            ...car,
                            selectedCategories: [],
                            selectedCategory: ''
                        };
                    } else {
                        updatedData[key] = car;
                    }
                }
            
                return {
                    ...prevData,
                    ...updatedData
                };
            });
            // setCarData(prevData => ({
            //     ...prevData,
            //     [selectedCar]: {
            //         ...prevData[selectedCar],
            //         selectedCategories: [],
            //         selectedCategory:''
            //     }
            // }));
        }
    };
    
    // const handleLorChange = (index, value) => {
    //     if (!carData[selectedCar]) return;
    
    //     setCarData(prevData => {
    //         const newLorMapping = [...prevData[selectedCar].advancedSettings.lorMapping];
    //         newLorMapping[index] = value;
    //         return {
    //             ...prevData,
    //             [selectedCar]: {
    //                 ...prevData[selectedCar],
    //                 advancedSettings: {
    //                     ...prevData[selectedCar].advancedSettings,
    //                     lorMapping: newLorMapping
    //                 }
    //             }
    //         };
    //     });
    // };
  
    // const [carData, setCarData] = useState({});

    const handleInputChange = (field, value) => {
        // console.log('field',field)
        // console.log('value',value)
        // console.log('carData',carData)
        // console.log('ota',selectedOTA)
        if (!carData[selectedCar]) return;

        if (field === 'competitionType') {
            // console.log('Im here');
            // console.log('field',field)
            // console.log('value',value)
            if (value == 'OTA Assigned Category'){
                setCarData(prevData => ({
                    ...prevData,
                    [selectedCar]: {
                        ...prevData[selectedCar],
                        selectedCategories: [],
                        selectedCategory:''
                    }
                }));
                if(selectedOTA == ''  || selectedOTA  == undefined){
                    Swal.fire('Please Select OTA at the top to have the Competition Type as OTA Assigned Category')
                    return;
                }else if(selectedOTA == "Priceline"){
                    setCategories(pricelineOptions)
                }else if (selectedOTA == "Expedia"){
                    setCategories(expediaOptions)
                }
                // setCategories
            }else if (value == 'SIPP Code Based'){
                setCarData(prevData => ({
                    ...prevData,
                    [selectedCar]: {
                        ...prevData[selectedCar],
                        selectedCategories: [],
                        selectedCategory:''
                    }
                }));
    
            }

           
            // setCarData(prevData => {
            //     const updatedData = {};
            //     console.log('prevData',prevData)
            
            //     for (const [key, car] of Object.entries(prevData)) {
            //         if (car.competitionType === "OTA Assigned Category") {
            //             updatedData[key] = {
            //                 ...car,
            //                 selectedCategories: [],
            //                 selectedCategory: ''
            //             };
            //         } else {
            //             updatedData[key] = car;
            //         }
            //     }
            
            //     return {
            //         ...prevData,
            //         ...updatedData
            //     };
            // });
        }

        setCarData(prevData => ({
            ...prevData,
            [selectedCar]: {
                ...prevData[selectedCar],
                [field]: value
            }
        }));
    };
    

    // const handleSaveAndNext = () => {
    //     const currentIndex = listOfExistingFleetSetUp.findIndex(car => car.SIPP_CODE === selectedCar);
    //     const nextIndex = currentIndex + 1;
    //     if (nextIndex < listOfExistingFleetSetUp.length) {
    //         const nextCar = listOfExistingFleetSetUp[nextIndex].SIPP_CODE;
    //         handleCarChange(nextCar);
    //     }
    // };

    const editStrategy = (name) => {
        // console.log('updatedRecord1',updatedRecord)
        Swal.fire({
            title: 'Edit this Strategy?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                const targetStrategyName = name
                setUpdatedRecord(true)
                const record = listOfExistingStrategies.find(record => record.strategyName === targetStrategyName);
                setLgShow(true)
                // console.log('record',record)
                // console.log('record2',record.selectedOTA)
                if(record.selectedOTA == 'Expedia'){
                    setCategories(expediaOptions)
                }else if (record.selectedOTA  == 'Priceline'){
                    setCategories(pricelineOptions)
                }
                setSelectedOTA(record.selectedOTA)
                setStrategyName(record.strategyName)
                const initialCarData = {};
                listOfExistingFleetSetUp.forEach(val => {
                    initialCarData[val.SIPP_CODE] = {
                        competitionType: null,
                        selectedCategory: "",
                        selectedCategories: [],
                        selectedStrategy: '',
                        selected: [],
                        dollarPercentage: "",
                        value: "",
                        advancedSettings: {
                            lorMapping: [], 
                            strategies: [], 
                            chasingCriteria: '', 
                        }
                    };
                });
                const mergedCarData = { ...initialCarData, ...record['carData'] };
                setCarData(mergedCarData);
                // setCarData(record['carData'])  
            }
            
        })
    }

    const handleSaveAndNext = () => {
        // Find the index of the currently selected car class
        const currentIndex = listOfExistingFleetSetUp.findIndex(car => car.SIPP_CODE === selectedCar);
        
        // Calculate the index of the next car class
        const nextIndex = currentIndex + 1;
        
        // Check if the next index is within the bounds of the array
        if (nextIndex < listOfExistingFleetSetUp.length) {
            // Retrieve the SIPP code of the next car class
            const nextCar = listOfExistingFleetSetUp[nextIndex].SIPP_CODE;
            
            // Switch to the next car class
            handleCarChange(nextCar);
        }
        // You may add logic here to handle what to do if the current car class is the last one
    };
    

    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([]);
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [companyName, setCompanyName] = useState("");
    const [companyDetail, setCompanyDetail] = useState([{}]);
    const [company_id, setCompany_id] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationName, setLocationName] = useState("");
    const [updatedRecord, setUpdatedRecord] = useState(false);
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [lgShow, setLgShow] = useState(false);
    const [finalShow, setFinalShow] = useState(false);
    const [advLgShow, setAdvLgShow] = useState(false);
    const [listOfExistingFleetSetUp, setListOfExistingFleetSetUp] = useState([]);
    const [masterFleetSetUp, setMasterFleetSetUp] = useState([]);

    const [selectedStrategyValues, setSelectedStrategyValues] = useState(Array(listOfExistingFleetSetUp.length).fill('')); // Array to hold selected values for each dropdown
    const chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    const cellStyle = {
        width: '150px',
        textAlign: 'center',
        padding: '5px',
        boxSizing: 'border-box'
    };
    
    
    
    // Usage in your component
    const chunkedFleetSetup = chunkArray(listOfExistingFleetSetUp, 10);
    // const handleStrategyChange = (index, value) => {
    //     const updatedValues = [...selectedStrategyValues];
    //     updatedValues[index] = value;
    //     setSelectedStrategyValues(updatedValues);
    // };

    // const handleStrategyChange = (index, value) => {
    //     setCarData(prevData => {
    //         const updatedCarData = { ...prevData };
    //         if (!updatedCarData[selectedCar].advancedSettings.strategies) {
    //             updatedCarData[selectedCar].advancedSettings.strategies = {};
    //         }
    //         updatedCarData[selectedCar].advancedSettings.strategies[index] = value;
    //         return updatedCarData;
    //     });
    // };

    const handleStrategyChange = (index, value, sippcode) => {
        // console.log("sippcode",sippcode)
        if (!carData[selectedCar]) return;
        // console.log("sippcode",sippcode)
        setCarData(prevData => {
            const newStrategies = [...prevData[selectedCar].advancedSettings.strategies];
            const sippIndex = newStrategies.findIndex(strategy => strategy.SIPP_CODE === sippcode);

            if (sippIndex !== -1) {
                newStrategies[sippIndex] = {
                    ...newStrategies[sippIndex],
                    value: value
                };
            } else {
                // If the SIPP_CODE is not found, add a new entry
                newStrategies.push({ SIPP_CODE: sippcode, value: value });
            }
    
            return {
                ...prevData,
                [selectedCar]: {
                    ...prevData[selectedCar],
                    advancedSettings: {
                        ...prevData[selectedCar].advancedSettings,
                        strategies: newStrategies
                    }
                }
            };
        });
    };
    

    const [carImageHash, setCarImageHash] = useState({});
    const [selectedCar, setSelectedCar] = useState(listOfExistingFleetSetUp[0]?.SIPP_CODE || '');
    // const [selectedCar, setSelectedCar] = useState(null);
    const [competitionType, setCompetitionType] = useState("");
    const [selectedOTA, setSelectedOTA] = useState("");
    const [strategyName, setStrategyName] = useState("");
    const [CompetingCarCategories, setCompetingCarCategories] = useState([]);
    const [selected, setSelected] = useState([]);
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [expediaOptions, setExpediaOptions] = useState(['Mini','Economy', 'Compact', 'MidSize', 'Standard', 'Full-Size', 'Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);
    // const [pricelineOptions, setPricelineOPtions]  = useState(['Car','Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury Car', 'Sports Car', 'SUV', 'Compact SUV', 'Midsize-SUV', 'Standard SUV', 'Standard Elite SUV', 'Full-Size SUV','Full-Size Elite SUV', 'Premium SUV', 'Premium Crossover', 'Luxury SUV', 'Van', 'Minivan']);
    const [categories, setCategories] = useState([])
    // const [expediaOptions, setExpediaOptions]  = useState(['Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);
    const [pricelineOptions, setPricelineOPtions]  = useState(['Car','Mini Car','Economy', 'Compact','Mid-Size', 'Standard', 'Full-Size','Wagon','Premium', 'Luxury Car', 'Sports Car', 'SUV', 'Compact SUV', 'Midsize-SUV', 'Standard SUV', 'Standard Elite SUV', 'Full-Size SUV','Full-Size Elite SUV','Mid-Size Elite SUV', 'Premium SUV','Premium Crossover', 'Pickup Truck', 'Luxury SUV', 'Van', 'Minivan', "Supplier's Choice", 'Convertible', 'Passenger Van', 'Electric Vehicle']);

    // const [categories, setCategories] =useState(['Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Van', 'Pickup', 'Sports Car', 'Other']);
    const [strategies, setStrategies] = useState(['Lowest', '2nd Lowest', '3rd Lowest','Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport','Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal','Lowest among all the Car Classes', 'Leave as it is']);

    // const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [selectedCategories, setSelectedCategories] = useState([]);

    // const handleAddCategory = () => {
    //     if (selectedCategory && !selectedCategories.includes(selectedCategory)) {
    //         setSelectedCategories([...selectedCategories, selectedCategory]);
    //     }
    // };

    const handleAddCategory = () => {
        if (carData[selectedCar].selectedCategory && !carData[selectedCar].selectedCategories.includes(carData[selectedCar].selectedCategory)) {
            setCarData({
                ...carData,
                [selectedCar]: {
                    ...carData[selectedCar],
                    selectedCategories: [...carData[selectedCar].selectedCategories, carData[selectedCar].selectedCategory],
                    selectedCategory: ""
                }
            });
        }
    };

    const handleCarChange = (sippCode) => {
        setSelectedCar(sippCode);
        if (!carData[sippCode]) {
            setCarData({
                ...carData,
                [sippCode]: {
                    competitionType: null,
                    selectedCategory: "",
                    selectedCategories: [],
                    selectedStrategy: '',
                    selected: [],
                    dollarPercentage: "",
                    value: "",
                    advancedSettings: {
                        lorMapping: [], 
                        strategies: [], 
                        chasingCriteria: '', 
                    }
                }
            });
        }
    };

    const [carData, setCarData] = useState(
        listOfExistingFleetSetUp.reduce((acc, carClass) => {
            acc[carClass.SIPP_CODE] = {
                competitionType: null,
                selectedCategory: '',
                selectedCategories: [],
                selectedStrategy: '',
                dollarPercentage: '',
                value: '',
                selected: [],
                advancedSettings: {
                    lorMapping: [], 
                    strategies: [], 
                    chasingCriteria: ''
                }
            };
            return acc;
        }, {})
    );

    useEffect(() => {
        const initialCarData = {};
        listOfExistingFleetSetUp.forEach(val => {
            if (!carData[val.SIPP_CODE]) {
                initialCarData[val.SIPP_CODE] = {
                    competitionType: null,
                    selectedCategory: "",
                    selectedCategories: [],
                    selectedStrategy: '',
                    selected: [],
                    dollarPercentage: "",
                    value: "",
                    advancedSettings: {
                        lorMapping: [], 
                        strategies: [], 
                        chasingCriteria: '', 
                    }
                };
            }
        });
        setCarData(prevCarData => ({ ...prevCarData, ...initialCarData }));
    }, [listOfExistingFleetSetUp]);

    useEffect(() => {
        setCarData(carData)

    });

    const newStrategy = () => {
        setLgShow(true)
        setUpdatedRecord(false)
        setStrategyName('')
        setSelectedOTA('')
        setCarData({});
        let sippCode5;
        if (listOfExistingFleetSetUp && listOfExistingFleetSetUp.length > 0 && listOfExistingFleetSetUp[0].SIPP_CODE) {
            sippCode5 = listOfExistingFleetSetUp[0].SIPP_CODE;
            listOfExistingFleetSetUp.forEach((fleet) => {
                if (fleet.SIPP_CODE) {
                    const selectedCar = fleet.SIPP_CODE;  // or however you determine the selectedCar
        
                    setCarData((prevCarData) => ({
                        ...prevCarData,
                        [selectedCar]: {
                            competitionType: null,
                            selectedCategory: "",
                            selectedCategories: [],
                            selectedStrategy: '',
                            selected: [],
                            dollarPercentage: "",
                            value: "",
                            advancedSettings: {
                                lorMapping: [],
                                strategies: [],
                                chasingCriteria: '',
                            },
                        },
                    }));
                }
            });
        } else {
            sippCode5 = '';
        }
        // console.log('sippCode5',listOfExistingFleetSetUp)
        // console.log('carData',carData)

        setSelectedCar(sippCode5);
        // console.log('selectedCar',selectedCar)
        // if (selectedCar) {
        //     console.log('im here')
        //     setCarData({
        //         ...carData,
        //         [selectedCar]: {
        //             competitionType: null,
        //             selectedCategory: "",
        //             selectedCategories: [],
        //             selectedStrategy: '',
        //             selected: [],
        //             dollarPercentage: "",
        //             value: "",
        //             advancedSettings: {
        //                 lorMapping: [], 
        //                 strategies: [], 
        //                 chasingCriteria: '', 
        //             }
        //         }
        //     });
        // }
        // setCarData({});
        // let sippCode5;
        // if (listOfExistingFleetSetUp && listOfExistingFleetSetUp.length > 0 && listOfExistingFleetSetUp[0].SIPP_CODE) {
        //     sippCode5 = listOfExistingFleetSetUp[0].SIPP_CODE;
        // } else {
        //     sippCode5 = '';
        // }
        // setSelectedCar(sippCode5);
        // const sippCode5 = listOfExistingFleetSetUp[0].SIPP_CODE

        // if (!carData[sippCode5]) {
        //     setCarData({
        //         ...carData,
        //         [sippCode5]: {
        //             competitionType: null,
        //             selectedCategory: "",
        //             selectedCategories: [],
        //             selectedStrategy: '',
        //             selected: [],
        //             dollarPercentage: "",
        //             value: "",
        //             advancedSettings: {
        //                 lorMapping: [], 
        //                 strategies: [], 
        //                 chasingCriteria: '', 
        //             }
        //         }
        //     });
        // }
    }

    const handleSubmitAll = () => {
        // console.log("final Car Data",carData)
        // console.log('Saved data of all car classes:', Object.values(carData));
        // console.log('accountId',company_id)
        // console.log('locationId',locationId)
        if (strategyName === '') {
            Swal.fire('Please Enter Strategy Name!')
            return;
        }
        if (selectedOTA === '') {
            Swal.fire('Please select OTA')
            return;
        }
        let name = '';
        if (updatedRecord) {
            name = strategyName;
        } else {
            // name = strategyName;
            name = strategyName + '_' + locationId[0];
        }

        const errorsBySippCode = [];

        // Collect errors by SIPP_CODE
        Object.keys(carData).forEach(sippCode => {
            if (sippCode !== ''){
                const data = carData[sippCode];
            const errors = [];

            if (!data.competitionType) {
                errors.push('Competition Type');
            }
            if (!data.selectedCategories.length) {
                errors.push('Selected');
            }
            if (!data.selectedStrategy) {
                errors.push('Strategy');
            }
            if (data.dollarPercentage === "") {
                errors.push('Value/Percentage');
            }
            if (data.value === "") {
                errors.push('Value');
            }
            
            

            // If there are errors for this SIPP_CODE, construct error message
            if (errors.length > 0) {
                errorsBySippCode.push({
                    sippCode: sippCode,
                    missingFields: errors.join(', ')
                });
            }

            }
            
        });

        // Prepare data for Swal.fire in table format
        let tableHtml = '<table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">';
        tableHtml += '<tr style="background-color: #f2f2f2;"><th style="padding: 8px; text-align: center;">SIPP Code</th><th style="padding: 8px; text-align: center;">Missing Fields</th></tr>';
        
        errorsBySippCode.forEach(({ sippCode, missingFields }) => {
            tableHtml += `<tr><td style="padding: 8px; font-weight: bold; border-bottom: 1px solid #ddd;">${sippCode}</td><td style="padding: 8px; border-bottom: 1px solid #ddd;">${missingFields}</td></tr>`;
        });

        tableHtml += '</table>';

        if (errorsBySippCode.length > 0) {
            Swal.fire({
                title: 'Please Enter these Missing Fields to Save the Custom Strategy',
                html: tableHtml,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    container: 'swal2-custom-layout'
                },
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
            });
            return;
        }

        

        

        // Object.keys(carData).forEach(sippCode => {

            
        //     const data = carData[sippCode];
            
        //     if (!data.competitionType) {
        //         errors.push(`Competition Type is empty for ${sippCode}`);
        //     }
        //     if (data.dollarPercentage === "") {
        //         errors.push(`Dollar Percentage is empty for ${sippCode}`);
        //     }
        //     if (data.value === "") {
        //         errors.push(`Value is empty for ${sippCode}`);
        //     }
        //     if (!data.selectedCategories.length) {
        //         errors.push(`Selected is empty for ${sippCode}`);
        //     }
        //     if (data.value === "") {
        //         errors.push(`Value is empty for ${sippCode}`);
        //     }
        //     if (!data.selectedStrategy) {
        //         errors.push(`Selected Strategy is empty for ${sippCode}`);
        //     }
            
        // });
        // if (errors.length) {
        //     Swal.fire({
        //         title: 'Validation Error',
        //         text: errors.join("\n"),
        //         icon: 'error',
        //         confirmButtonText: 'Ok'
        //     });
        // }
        // if (errors.length) {
        //     alert(errors.join("\n"));
        // }
        // console.log('carData 11111',carData)
        // return 'success'

        const combinedData = {
            carData: carData,
            accountId: company_id,
            strategyName:name,
            selectedOTA:selectedOTA,
            updatedRecord:updatedRecord,
            locationId: locationId[0],
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        axios.post(baseURL + "/save_custom_strategy", combinedData).then((res) => {
            // if ()
            // console.log('resss',res)
            if (res !== null && 'data' in res && typeof res.data === 'string') {
                if (res.data == 'Duplicate Entry on Strategy Name'){
                    Swal.fire({
                        position: "middle",
                        icon: "failure",
                        title: res.data,
                        showConfirmButton: false,
                        timer: 3000,
                      });
                }else{
                    Swal.fire({
                        position: "middle",
                        icon: "success",
                        title: res.data,
                        showConfirmButton: false,
                        timer: 3000,
                      });
                      axios
                        .get(baseURL + "/get_custom_strategy/" + locationId)
                        .then(response => {
                            if(response.data !== ''){
                                // console.log(response.data)
                                // console.log('strategyData', response.data[0]['carData'])
                                // setCarData(response.data[0]['carData'])
                                setListOfExistingStrategies(response.data)
                            }
                        })
                        .catch(error => {
                            setCarData({});
                            console.error("Second Axios Request Error:", error);
                        });
                }
                  setFinalShow(false);
                  setLgShow(false);  
            } else {
                Swal.fire({
                    position: "middle",
                    icon: "failure",
                    title: 'No Response',
                    showConfirmButton: false,
                    timer: 3000,
                  });
                setFinalShow(false);
                setLgShow(false); 
            }
        
          });
        
    };


    const handleChasingCriteriaChange = (value) => {
        if (!carData[selectedCar]) return;
    
        setCarData(prevData => ({
            ...prevData,
            [selectedCar]: {
                ...prevData[selectedCar],
                advancedSettings: {
                    ...prevData[selectedCar].advancedSettings,
                    chasingCriteria: value
                }
            }
        }));
    };

    

    // const handleRemoveCategory = (category) => {
    //     setSelectedCategories(selectedCategories.filter(c => c !== category));
    // };

    const handleRemoveCategory = (category) => {
        setCarData({
            ...carData,
            [selectedCar]: {
                ...carData[selectedCar],
                selectedCategories: carData[selectedCar].selectedCategories.filter(cat => cat !== category)
            }
        });
    };

    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const CompetitionTypeOptions = [
        { value: 'SIPP Code Based', label: 'SIPP Code Based' },
        { value: 'OTA Assigned Category', label: 'OTA Assigned Category' },
    ]
    const SelectedOTAOptions = [
        { value: 'Expedia', label: 'Expedia' },
        { value: 'Priceline', label: 'Priceline' },
    ]

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));



    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };
    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };
    const handleInputChangecompetitionType = (characterEntered) => {
        setCompetitionType(characterEntered.value);
        setSelectedCategories([])
    };
    const handleInputChangeSelectedOTA = (characterEntered) => {
        setSelectedOTA(characterEntered.value);
    };

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setCompanyDetail(res.data);
                });
        } else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['CustomStrategy_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setCompanyDetail(data);
                });
        }

        fetch(baseURL + `/get_existing_car_classes_standard`)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    setListOfExistingCarClasses(data)
                } else {
                    setListOfExistingCarClasses([])
                }
            });
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setCompany_id(temp_company_id);
            setLocationName('');
            setLocationId('');
            setLocationDetail([{}]);
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    } else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    } else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            });
                    }
                });
        }
    }, [companyName]);
    useEffect(() => {
       setUpdatedRecord(updatedRecord)
    //    console.log('updatedRecord5',updatedRecord)
    }, [updatedRecord]);

    useEffect(() => {
        let temp_location_id = '';
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id);
            setLocationId(temp_location_id);
            // console.log("company_id",company_id)
            fetch(baseURL + `/get_existing_fleet_setup/${temp_location_id}`)
            // fetch(baseURL + `/get_existing_car_classes_standard/${company_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        // console.log("data",data)
                        axios.get(baseURL + "/rp_get_car_image")
                        // axios.get(baseURL + `/get_car_image_by_account/${company_id}`)
                            .then((res) => {
                                // console.log("res",res)
                                // console.log("res image",res.data.image_path)

                                setCarImageHash(res.data)
                                setListOfExistingFleetSetUp(data)
                                let temp_taxes_data = []
                                if (data.length > 0) {
                                    setSelectedCar(data[0]['SIPP_CODE'])

                                    for (let i = 0; i < data.length; i++) {
                                        temp_taxes_data.push({ 'value': data[i].SIPP_CODE, 'label': data[i].SIPP_CODE });
                                    }
                                    setCompetingCarCategories(temp_taxes_data)
                                }
                            })

                            axios
                            .get(baseURL + "/get_all_competitor_setup_info/" + temp_location_id)
                            .then(response => {
                            const newArray = response.data.selected_items.map(subArray => subArray[0]);
                            setCompetitorsList(newArray)
                            })
                            .catch(error => {
                            console.error("Second Axios Request Error:", error);
                            });

                            fetch(baseURL + "/get_existing_car_classes_standard" )
                            .then((res) => res.json())
                            .then((data) => {
                                if (data.length > 0) {
                                    const standardSippCodeOptions = data.map((value) => ({
                                        value: value.SIPP_CODE
                                    }));
                                    const standardSippCodeArray = standardSippCodeOptions.map(option => option.value);
                                    setMasterFleetSetUp(standardSippCodeArray)
                
                                    // setCarDetailsInOption1(standardSippCodeArray);
                                    // setCarDetailsInOption2(standardSippCodeArray);
                                    // setCarDetailsInOptionConstant(standardSippCodeArray)
                
                                }
                            })

                            axios
                            .get(baseURL + "/get_custom_strategy/" + temp_location_id)
                            .then(response => {
                                if (response !== null && 'data' in response && typeof response.data === 'object' && response.data.length !== 0) {
                                // if(response.data !== ''){
                                    // setCarData(response.data[0]['carData'])
                                    setListOfExistingStrategies(response.data)
                                }else{
                                    setCarData({});
                                    setListOfExistingStrategies([])
                                    Swal.fire("There are no strategies available for the current location")
                                }
                            })
                            .catch(error => {
                                setCarData({});
                                console.error("Second Axios Request Error:", error);
                            });
                    }
                    else {
                        setListOfExistingFleetSetUp([])
                    }
                })
        }
    }, [locationName]);

    const color_pallete = ['#B6D7A8', '#FFE599', '#EA9999']

    return (
        <>
            {/* New Strategy start */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-70w"
                centered
            >
                <Modal.Header closeButton style={{ background: '#FFA500' }} >
                    <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '20px' }} >
                        New Strategy
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-1">
                        <div className="col-12 mb-2">
                        <div className="row" style={{ background: '#54ADD1', padding: '15px',marginBottom:'2%' }}>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ fontSize: '17px', paddingLeft: '10px' }}>
                                <b>Strategy Name</b>
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <input
                                type="text"
                                name="inputField"
                                className="form-control"
                                value={strategyName}
                                onChange={(e) => setStrategyName(e.target.value)}
                                disabled={!!updatedRecord}
                                style={{ fontWeight: 'bold' }}
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ fontSize: '17px', paddingLeft: '10px' }}>
                                <b>Select OTA</b>
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Select
                                value={SelectedOTAOptions.find(option => option.value === selectedOTA)}
                                onChange={(option) => handleOta(option.value)}
                                options={SelectedOTAOptions.map((val) => ({ value: val.value, label: val.value }))}
                                placeholder="Select OTA"
                                // disabled={!!updatedRecord}
                                isDisabled={!!updatedRecord}
                                styles={{
                                    option: (provided) => ({
                                    ...provided,
                                    fontWeight: 'bold',
                                    }),
                                    singleValue: (provided) => ({
                                    ...provided,
                                    fontWeight: 'bold',
                                    }),
                                }}
                                />
                            </div>
                            </div>
                             {/* <div className="row" style={{background: '#54ADD1', marginTop:'2%'}}>
                                    
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="company_name" className="form-label" style={{ fontSize: '20px', paddingLeft: '10px',marginLeft:'10%' }}>
                                            <b>Strategy Name</b>
                                        </label>
                                    </div>
                                    <div className="col-md-3 mb-3" style={{marginLeft:'-10%'}}>
                                        <input
                                            type="text"
                                            name="inputField"
                                            className="form-control"
                                            value={strategyName}
                                            onChange={(e) => setStrategyName(e.target.value)}
                                            disabled={!!updatedRecord}
                                            style={{ fontWeight: 'bold' }}                                         
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, fontSize: '20px', paddingLeft: '10px',marginLeft:'10%' }}>
                                            <b>Select OTA</b>
                                        </label>
                                    </div>
                                    <div className="col-md-3 mb-3" style={{marginLeft:'-13%'}}>
                                        <Select
                                            value={SelectedOTAOptions.find(option => option.value === selectedOTA)}
                                            onChange={(option) => handleOta(option.value)}
                                            options={SelectedOTAOptions.map((val) => ({ value: val.value, label: val.value }))}                                   
                                            placeholder="Select OTA"  
                                            styles={{
                                                option: (provided) => ({
                                                  ...provided,
                                                  fontWeight: 'bold',
                                                }),
                                                singleValue: (provided) => ({
                                                  ...provided,
                                                  fontWeight: 'bold',
                                                }),
                                              }}
                                        />
                                    </div>
                             </div> */}
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    <label htmlFor="name" className="form-label d-flex justify-content-center" style={{ fontSize: '20px' }}>
                                        <b>Sipp Codes</b>
                                    </label>
                                    {
                                        listOfExistingFleetSetUp.map((val, i) => (
                                            <div className='row' key={i}>
                                                <label htmlFor="name" className="form-label d-flex justify-content-center"
                                                    style={{
                                                        fontSize: '20px', backgroundColor: selectedCar === val.SIPP_CODE ? color_pallete[1] : color_pallete[0],
                                                        cursor: 'pointer', padding: '5px'
                                                    }}
                                                    onClick={() => handleCarChange(val.SIPP_CODE)}>
                                                    {selectedCar === val.SIPP_CODE ?
                                                        <strong>{val.SIPP_CODE}</strong> :
                                                        val.SIPP_CODE
                                                    }
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <div className="col-md-3 mb-2">
                                    <label>
                                            NEW Classes
                                    </label>
                                </div> */}
                                <div className="col-md-9 mb-2">
                                    {selectedCar && carData[selectedCar] && (
                                        <> 
                                         <div className='row'>
                                            <div className='col-md-12 d-flex justify-content-center'>
                                                    <Card sx={{ maxWidth: 245, border: '2px dotted grey' }}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: '100%', height: 150, objectFit: 'contain' }} // Adjust image to fit within dimensions
                                                            image={baseURL + "/static/uploads/" + carImageHash[selectedCar]}
                                                            title="fleets"
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h7" component="div">
                                                            Selected <strong>{selectedCar}</strong>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            </div>
                                       
                                        
                                            {/* <div className='row'>
                                                <div className='col-md-12 d-flex justify-content-center'>
                                                    <Card sx={{ maxWidth: 245 }}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: 221 }}
                                                            image={baseURL + "/static/uploads/" + carImageHash[selectedCar]}
                                                            title="fleets"
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h7" component="div">
                                                                Selected <strong>{selectedCar}</strong>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            </div> */}
                                            <div className='row'>
                                                <div className='col-md-12 '>
                                                    <div className="row pt-4">
                                                        <div className="col-md-4 mb-3">
                                                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, fontSize: '15px', paddingLeft: '10px' }}>
                                                                Competition Type *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Select
                                                                value={{ value: carData[selectedCar].competitionType, label: carData[selectedCar].competitionType || "Select Competition Type" }}
                                                                inputId="competitionType"
                                                                name="competitionType"
                                                                options={CompetitionTypeOptions}
                                                                onChange={(option) => handleInputChange("competitionType", option.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Competing Selection 
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select
                                                                value={carData[selectedCar].selectedCategory}
                                                                onChange={(e) => handleInputChange("selectedCategory", e.target.value)}
                                                                style={{ width: '100%', height: '40px' }} 
                                                            >
                                                                <option value="">Select</option>
                                                                {
                                                                    carData[selectedCar].competitionType === 'OTA Assigned Category' ?
                                                                        categories.map((category) => (
                                                                            <option key={category} value={category}>{category}</option>
                                                                        ))
                                                                        : masterFleetSetUp.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <button className='btn btn-secondary' onClick={handleAddCategory}>Add</button>
                                                        </div>
                                                    </div>
                                                    <div className='row pt-3'>
                                                        <div className="col-md-4">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Selected *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3" style={{ border: '1px solid #AEAEAE', borderRadius: '8px', padding: '10px' }}>
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                {carData[selectedCar].selectedCategories.map((category) => (
                                                                    <span key={category} style={{ marginRight: '15px' }}>
                                                                        {category} <span
                                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                                            onClick={() => handleRemoveCategory(category)}
                                                                        >
                                                                            <HighlightOffIcon />
                                                                        </span>
                                                                    </span>
                                                                ))}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Strategy *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <select
                                                                value={carData[selectedCar].selectedStrategy}
                                                                onChange={(e) => handleInputChange("selectedStrategy", e.target.value)}
                                                                style={{ width: '100%', height: '40px' }} 
                                                            >
                                                                <option value="">Select a Strategy</option>
                                                                {
                                                                    strategies.map((strategy) => (
                                                                        <option key={strategy} value={strategy}>{strategy}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Competitor Exclusion
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <MultiSelect
                                                                options={competitorsList}
                                                                value={carData[selectedCar].selected}
                                                                onChange={(selected) => handleInputChange("selected", selected)}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Value/Percentage *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <select
                                                                value={carData[selectedCar].dollarPercentage}
                                                                onChange={(e) => handleInputChange("dollarPercentage", e.target.value)}
                                                                style={{ width: '100%', textDecoration: 'none', height: '35px' }}
                                                            >
                                                                <option value="">Choose One</option>
                                                                <option value="percentage">Percentage (%)</option>
                                                                <option value="value">Value</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                                                Value *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <input
                                                                type="number"
                                                                name="inputField"
                                                                className="form-control"
                                                                value={carData[selectedCar].value}
                                                                onChange={(e) => handleInputChange("value", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <Button variant="contained" color="primary" startIcon={<SettingsIcon />} onClick={() => { setAdvLgShow(true) }}>Advanced Settings</Button>
                                                        </div>
                                                        <div className="col-md-4 mb-3"></div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col-md-6 d-flex justify-content-end">
                                                    <Button variant="contained" color="secondary" startIcon={<KeyboardTabIcon />} onClick={handleSaveAndNext}>Save and Next</Button>
                                                </div>
                                                {/* <Button variant="contained" color="success" onClick={handleSubmitAll}>Submit </Button> */}
                                                { accessType === 'View' ? "" :
                                                <>          
                                                    <div className="col-md-6 d-flex justify-content-start">
                                                        <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={handleSubmitAll}>Submit All</Button>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
            <Modal
                size="lg"
                dialogClassName="modal-70w"
                show={advLgShow}
                onHide={() => setAdvLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ zIndex: 9999 }}
                centered
            >
                <div style={{ width: '180%', maxWidth: 'unset', marginLeft: '-40%' }}>
                    <Modal.Header style={{ backgroundColor: '#B6D7A8', textAlign: 'center' }} closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg" style={{ textAlign: 'center', width: '100%' }}>
                            Advanced Settings
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: 'white', overflowX: 'auto' }}>
                        <form style={{ fontSize: '12px' }}>
                            <h3>LOR Mapping</h3>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead style={{ paddingBottom: '30px' }}>
                                    <tr style={{ textAlign: 'center', paddingBottom: '30px' }}>
                                        <th>LOR</th>
                                        <th>1</th>
                                        <th>2</th>
                                        <th>3</th>
                                        <th>4</th>
                                        <th>5</th>
                                        <th>6</th>
                                        <th>7</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ textAlign: 'center' }}>
                                        <td style={{ fontWeight: 'bold', width: '10% !important' }}>Map Against</td>
                                        {[...Array(7)].map((_, index) => (
                                            <td key={index}>
                                                <select 
                                                    className="dropdown" 
                                                    style={{ textAlign: 'center' }} 
                                                    value={carData[selectedCar]?.advancedSettings?.lorMapping[index] || ''}
                                                    onChange={(e) => handleLorChange(index, e.target.value)}
                                                >
                                                    <option value="" selected>Select a LOR</option>
                                                    {lorOptions.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                        <th>Car Class</th>
                        <th>Strategy</th>
                        {chunkedFleetSetup.length > 1 && chunkedFleetSetup.slice(1).map((_, index) => (
                            <React.Fragment key={index}>
                                <th>Car Class</th>
                                <th>Strategy</th>
                            </React.Fragment>
                        ))}
                    </tr>
                {/* </thead>
                <tbody> */}
                    {Array.from({ length: 10 }).map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {chunkedFleetSetup.map((chunk, chunkIndex) => (
                                <React.Fragment key={chunkIndex}>
                                    {chunk[rowIndex] ? (
                                        <>
                                            <td>{chunk[rowIndex].SIPP_CODE}</td>
                                            <td>
                                                <select
                                                    className="dropdown"
                                                    style={{ textAlign: 'center' }}
                                                    value={
                                                        carData[selectedCar]?.advancedSettings?.strategies.find(
                                                            strategy => strategy.SIPP_CODE === chunk[rowIndex].SIPP_CODE
                                                        )?.value || ''
                                                    }
                                                    // value={carData[selectedCar]?.advancedSettings?.strategies[(chunkIndex * 10) + rowIndex] || ''}
                                                    onChange={(e) => handleStrategyChange((chunkIndex * 10) + rowIndex, e.target.value, chunk[rowIndex].SIPP_CODE)}
                                                >
                                                    <option value="">Select a strategy</option>
                                                    {strategyOptions.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </tr>
                        ))}
                                    {/* <tr>
                                        <th>Car Class</th>
                                        <th>Strategy</th>
                                        {chunkedFleetSetup.length > 1 && chunkedFleetSetup.slice(1).map((_, index) => (
                                            <React.Fragment key={index}>
                                                <th>Car Class</th>
                                                <th>Strategy</th>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    {Array.from({ length: 10 }).map((_, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {chunkedFleetSetup.map((chunk, chunkIndex) => (
                                                <React.Fragment key={chunkIndex}>
                                                    {chunk[rowIndex] ? (
                                                        <>
                                                            <td>{chunk[rowIndex].SIPP_CODE}</td>
                                                            <td>
                                                                <select
                                                                    className="dropdown"
                                                                    style={{ textAlign: 'center' }}
                                                                    value={carData[selectedCar]?.advancedSettings?.strategies[rowIndex] || ''}
                                                                    onChange={(e) => handleStrategyChange(rowIndex, e.target.value, chunk[rowIndex].SIPP_CODE)}
                                                                >
                                                                    <option value="" selected>Select a strategy</option>
                                                                    {strategyOptions.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td></td>
                                                            <td></td>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tr> */}
                                    
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-md-1 mb-3">
                                    <label htmlFor="chasing_criteria" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                        Chasing Criteria
                                    </label>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <select
                                        // value={carData[selectedCar].chasingCriteria}
                                        value={carData[selectedCar]?.advancedSettings?.chasingCriteria || ''}
                                        onChange={(e) => handleChasingCriteriaChange(e.target.value)}
                                    >
                                        <option value="">Choose One</option>
                                        <option value="chaseup">Chase Up</option>
                                        <option value="chasedown">Chase Down</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            {/* <Modal
                size="lg"
                show={finalShow}
                onHide={() => setFinalShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-70w"
                centered
            >
                <Modal.Header closeButton style={{ background: '#FFA500', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '20px', marginLeft:'40%' }} >
                        Header Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className="col-12 mb-2">    
                            <div className="row">
                                    <div className="col-md-2 mb-3">
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                            Strategy Name
                                        </label>
                                    </div>
                                    <div className="col-md-4 mb-3" style={{marginLeft:'-10%'}}>
                                        <input
                                            type="text"
                                            name="inputField"
                                            className="form-control"
                                            value={strategyName}
                                            onChange={(e) => setStrategyName(e.target.value)}
                                            disabled={!!updatedRecord}
                                                                                        
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, fontSize: '15px', paddingLeft: '10px' }}>
                                            Select OTA
                                        </label>
                                    </div>
                                    <div className="col-md-4 mb-3" style={{marginLeft:'-10%'}}>
                                        <Select
                                            value={SelectedOTAOptions.find(option => option.value === selectedOTA)}
                                            onChange={(option) => handleOta(option.value)}
                                            options={SelectedOTAOptions.map((val) => ({ value: val.value, label: val.value }))}                                   
                                            placeholder="Select OTA"  
                                        />
                                    </div>
                                </div>    
                                <div className="row pt-4">
                                    <div className="col-md-6 mb-3">
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-start" style={{marginLeft:'-10%'}}>
                                        <Button variant="contained" color="success" onClick={handleSubmitAll}>Submit </Button>
                                    </div>
                                </div>
                        </div>
                </Modal.Body>
            </Modal> */}
        {/* <Modal
            size="lg"
            dialogClassName="modal-70w"
            show={advLgShow}
            onHide={() => setAdvLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{
                zIndex: 9999,
            }}
            centered
        >
        <div style={{ width: '180%', maxWidth: 'unset', marginLeft:'-40%' }}>
            <Modal.Header style={{ backgroundColor: '#B6D7A8', textAlign: 'center' }} closeButton>
                <Modal.Title id="example-modal-sizes-title-lg" style={{ textAlign: 'center', width: '100%' }}>
                    Advanced Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: 'white', overflowX: 'auto' }}>
                <form style={{ fontSize: '12px' }}>
                    <h3>LOR Mapping</h3>
                    <table class = '000'style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead style={{paddingBottom:'30px'}}>
                            <tr style={{textAlign:'center', paddingBottom:'30px'}}>
                                <th>LOR</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                            </tr>
                        </thead>
                        <tbody class = 'oooo'>
                        <tr class = 'ooo' style={{textAlign:'center'}}>
                            <td class= 'oo' style={{fontWeight:'bold', width: '10% !important'}}>Map Against</td>
                            {[...Array(7)].map((_, index) => (
                                <td key={index}>
                                    <select 
                                        className="dropdown" 
                                        style={{textAlign:'center'}} 
                                        onChange={(e) => handleLorChange(index, e.target.value)}
                                    >
                                        <option value="" selected>Select a LOR</option>
                                        {lorOptions.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            ))}
                        </tr>
                        <tr>
                        <th style={cellStyle}>Car Class</th>
                        <th style={cellStyle}>Strategy</th>
                        {chunkedFleetSetup.length > 1 && chunkedFleetSetup.slice(1).map((_, index) => (
                            <React.Fragment key={index}>
                                <th style={cellStyle}>Car Class</th>
                                <th style={cellStyle}>Strategy</th>
                            </React.Fragment>
                        ))}
                        </tr>
                        {Array.from({ length: 10 }).map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {chunkedFleetSetup.map((chunk, chunkIndex) => (
                                    <React.Fragment key={chunkIndex}>
                                        {chunk[rowIndex] ? (
                                            <>
                                                <td style={cellStyle}>{chunk[rowIndex].SIPP_CODE}</td>
                                                <td style={cellStyle}>
                                                    <select
                                                        className="dropdown"
                                                        style={{ textAlign: 'center'}}
                                                        onChange={(e) => handleStrategyChange((chunkIndex * 10) + rowIndex, e.target.value)}
                                                    >
                                                        <option value="" selected>Select a strategy</option>
                                                        {strategyOptions.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td style={cellStyle}></td>
                                                <td style={cellStyle}></td>
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}      
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-1 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ fontSize: '15px', paddingLeft: '10px' }}>
                                Chase Criteria
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <select
                                value={carData[selectedCar].dollarPercentage}
                                onChange={(e) => handleInputChange("dollarPercentage", e.target.value)}
                            >
                                <option value="">Choose One</option>
                                <option value="percentage">Chase Up</option>
                                <option value="Dollar">Chase Down</option>
                            </select>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </div>
        </Modal> */}

       


            <div className="row" >
                <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-md-2 mb-3">
                        <label htmlFor="company_name" className="form-label" style={{ marginTop: 5, fontSize: '17px' }}>
                            Account Name
                        </label>
                    </div>
                    <div className="col-md-3 mb-3" style={{ marginLeft: '10px' }}>
                        <Select
                            value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                            inputId="company_name"
                            name="company_name"
                            options={companyNameOptions}
                            onChange={handleInputChangeCompanyName}
                        />
                    </div>
                </div>
                <div className="row pt-2" style={{ marginLeft: '10px' }}>
                    <div className="col-md-2 mb-3">
                        <label htmlFor="locationName" className="form-label" style={{ marginTop: 5, fontSize: '17px' }}>
                            Location Name
                        </label>
                    </div>
                    <div className="col-md-3 mb-3" style={{ marginLeft: '10px' }}>
                        <Select
                            value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                            inputId="locationName"
                            name="locationName"
                            options={locationNameOptions}
                            onChange={handleInputChangeLocationName}
                        />
                    </div>
                </div>
                { accessType === 'View' ? "" :
                <>
                    <div className="row pt-2" style={{ marginLeft: '10px' }}>
                        <div className='col-md-2'>
                            <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={(e) => newStrategy()}>New Strategy</Button>
                        </div>
                        <div className='col-md-10'></div>
                    </div>
                </>
                }
            </div >
            {listOfExistingStrategies.length > 0 ?
                <>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-2 mx-2" style={{ marginLeft: '40px' }}>
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '41px' }}>Existing Strategies</h5>

                            <table className="table table-hover" style={{ marginLeft: '40px', marginTop: '15px' }}>
                                <thead>
                                    <tr>
                                        <th style={{backgroundColor:'orange'}} scope="col">Strategy Name</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                                        <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingStrategies.map((val, i) => (
                                            <tr>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{val.strategyName}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{val.created_by}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}>{formatDate(new Date(val.created_date))}</td>
                                                <td style={{ backgroundColor: '#cfe2f3' }}><button className="btn success" onClick={(e) => editStrategy(val.strategyName)}> <EditIcon /></button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
            }
        </>
    );
}
