import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { Country } from "country-state-city";
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

export default function UpdateForm({accessType}) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [competitorName, setCompetitorName] = useState("");
  const [competitorCode, setCompetitorCode] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateErrorCodesDetails, setUpdateErrorCodesDetails] = useState([]);
  const [lgShow1, setLgShow1] = useState(true);
  const [lgShow2, setLgShow2] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [codeDescription, setCodeDescription] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [errorCodeDetails, setErrorCodeDetails] = useState([]);
  const [receiveMail, setReceiveMail] = useState(false);
  const [country, setCountry] = useState("");
  const [SIPPDetail, setSIPPDetail] = useState([]);
  const [SIPPName, setSIPPName] = useState("");
  const [carClassImage, setCarClassImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [competitorDetail, setCompetitorDetail] = useState([]);
  const [competitors, setCompetitors] = useState([]);


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Competitors_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }
    axios.get(baseURL + "/get_all_rac_info").then((res) => {
        setCompetitors(res.data);
    });

    axios.get(baseURL + "/get_competitors")
        .then((res) => {

            setCompetitorDetail(res.data)

        }).catch((e) => { });



  }, [updateFlag]);

  const competitorsOptions = competitors.map((value) => ({
    value: value.Code + " - " + value.Name,
    label: value.Code + " - " + value.Name,
  }));

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {
    if (companyDetail && companyName) {
      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;

      axios.get(baseURL + "/get_competitor/" + company_id)
      .then((res) => {

        setCompetitorDetail(res.data)

      }).catch((e) => { });


      axios.get(baseURL + "/get_car_class/" + company_id_temp)
        .then((res) => {

          let arr1 = res.data
          let car_class_arr = []
          let arr2 = [];

          for(let t=0; t<arr1.length; t++){
            if(car_class_arr.indexOf(arr1[t].SIPP_code) < 0){

              arr2.push(arr1[t]);
              car_class_arr.push(arr1[t].SIPP_code);
            }
          }

          setSIPPDetail(arr2);
          setcompany_id(company_id_temp);
          setUpdateFlag(!updateFlag);
          setLgShow1(false);
        });

    }//if condition ends

  }, [companyName]);

//   useEffect(() => {

//     if (companyCode && company_id) {

//       axios.get(baseURL + "/get_competitor/" + company_id + "-" + companyCode)
//         .then((res) => {

//           setCarsDetail(res.data)

//         }).catch((e) => { });
//     }

//   }, [updateFlag])

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));
  //Option for SIPP
  const SIPPOptions = SIPPDetail.map((value) => (

    { value: value.SIPP_code, label: value.SIPP_code }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  const handleInputChangeCompetitorCode = characterEntered => {
    setCompetitorCode(characterEntered.value);
  };




//   useEffect(() => {

//     if (companyCode && company_id) {

//       axios.get(baseURL + "/get_competitor/" + company_id + "-" + companyCode)
//         .then((res) => {

//           setCarsDetail(res.data)

//         }).catch((e) => { });
//     }

//   }, [updateFlag])

  //handle Fetch Click
  const handleFetch = (e) => {

    e.preventDefault();
    setLgShow1(true);

    if (country && company_id) {

      axios.get(baseURL + "/get_cars/" + company_id + "-" + country.name)
        .then((res) => {

        //   setCarsDetail(res.data)

        }).catch((e) => { });
    }
  }



  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }

  }, [lgShow1])


  const handleSubmit = (e) => {

    e.preventDefault();

    if (competitorName === "") {
      Swal.fire("Please Enter competitor Name");
      return;
    }
    else if (competitorCode === "") {
      Swal.fire("Please select Competitor Code");
      return;
    }

    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }


    Swal.fire({
      title: 'Do you want to save this car image ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      if (result.isConfirmed) {

        let formData = new FormData();
        formData.append("file", carClassImage);
        formData.append("_id", competitorName + "-" + competitorCode);
        formData.append("competitor_name", competitorName);
        formData.append("competitor_code", competitorCode);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);

        console.log(formData)

        axios.post(baseURL + "/save_competitor_info", formData)
          .then((res) => {
            if (res.data === 'Already Present') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Already exists, Please edit the existing one',
                showConfirmButton: false,
                timer: 3000
              })
            }
            else {

              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Saved Successfully !!',
                showConfirmButton: false,
                timer: 3000
              })
              // setUpdateFlag(!updateFlag);
              setUpdateFlag(!updateFlag)
              setCarClassImage(null);
            //   setCompanyCode("");
            //   setCompanyName("");
              setImageURL(null);

            }
          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//handlesubmit ends here


  const handleUpdate = (e) => {

    e.preventDefault();
    if (competitorName === "") {
      Swal.fire("Competitor Name field can't be empty");
      return;
    }
    else if (competitorCode === "") {
      Swal.fire("Please select Competitor Code");
      return;
    }
    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }

    Swal.fire({
      title: 'Do you want to save this Competitor image ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let formData = new FormData();
        formData.append("file", carClassImage);
        formData.append("_id", competitorName + "-" + competitorCode);
        formData.append("competitor_name", competitorName);
        formData.append("competitor_code", competitorCode);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);
        console.log(competitorName + "-" + competitorCode)

        axios.post(baseURL + "/save_competitor_info", formData)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateFlag(!updateFlag)
            setCarClassImage(null);
            setSIPPName("");
            setImageURL(null);
            setUpdateClicked(false);

          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//update rate rules ends

  const updateCompetitor = (val) => {

    Swal.fire({
      title: 'Do you want to edit this ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonStyle: 'font-size: 18px; color: red;',
    }).then((result) => {
      if (result.isConfirmed) {
        // document.getElementById("new_sipp").disabled = true;
        // document.getElementById("competitor_name").disabled = true;
        setCompetitorName(val.competitor_name);
        setCompetitorCode(val.competitor_code);
        setLgShow1(true);
        setLgShow2(true);
        setUpdateClicked(true);
        setImageURL(baseURL + "/static/uploads/" + val.image_path);

        handleBackClick();

      }
    })
  }

  const handleImageChange = (e) => {

    if (!(e.target.files[0].name.match(/\.(jpg|jpeg)$/))) {
      // .match(/\.(jpg|jpeg)$/)
      Swal.fire("Please select JPG or JPEG image format");
      return;
    }

    setCarClassImage(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]))
  }

  const downloadImage = (image_url, image) => {
    saveAs(image_url, image) // Put your image url here.
  }

  const handleCancel = (e) => {

    e.preventDefault();
    setSIPPName("");
    setLgShow1(true);
    setLgShow2(true);
    setUpdateClicked(false);
    setImageURL("");

  }
  const HandleAddForm = (e) => {
    setLgShow2(!lgShow2)
    setCompetitorName("")
    // document.getElementById("new_sipp").disabled = false;
    // document.getElementById("competitor_name").disabled = false;
  }

  return (
    <>
      <div className="row pt-2" ref={titleRef} id="title">
        <div className="col-8 mx-3">
        {accessType === 'View' ? "" :
            <>
                <div className="col-md-12 mb-5 d-flex justify-content-start">
                    <button type="submit" className="btn btn-primary" style={{fontSize:'12px'}} onClick={HandleAddForm}>
                        {!lgShow2 ? "+ Add New" : "- Hide"}
                    </button>
                </div>
            </>
        }    
          {/* <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead : </span> */}
        </div>
      </div>
        <div>
            <div className="row pt-1" style={{fontSize:'12px'}}>
              {lgShow2 ? 
              (
              <>
                <div className="col-10 mx-3">
                    <div className="row">
                        <div className="col-md-2 mb-3">
                        <label htmlFor="company_name" className="form-label" style={{color:'black', fontWeight:'600'}}>
                           Competitor Name
                        </label>
                        </div>
                        <div className="col-md-4 mb-3">
                        <input 
                            type="text"
                            className='form-control'
                            id="new_sipp"
                            value={competitorName} 
                            style={{fontSize:'12px', fontStyle:'bold'}}
                            placeholder="Enter competitor name"
                            onChange={(e) => setCompetitorName(e.target.value)}
                        />
                        </div>
                        <div className="col-md-2 mb-3" style={{marginLeft: '0%'}}>
                        <label htmlFor="company_name" className="form-label" style={{color:'black', fontWeight:'600'}}>
                            Competitor Code
                        </label>
                        </div>
                        <div className="col-md-4 mb-3">
                        <Select
                            value={{ value: competitorCode, label: (competitorCode ? competitorCode : "Select Competitor Code") }}
                            inputId="competitor_name"
                            name="competitor_name"
                            options={competitorsOptions}
                            onChange={handleInputChangeCompetitorCode}
                        />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '2%'}}>
                        <div className="col-md-2 mb-3">
                            <label htmlFor="choose_image" className="form-label" style={{color:'black', fontWeight:'600'}}>
                                Choose Image
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Button variant="contained" component="label">
                                Choose Image...
                                <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e)}/>
                            </Button>
                        </div>
                    </div>
                    {imageURL && (
                    <div className="row">
                      <div className="col-md-2 mb-3">
                      </div>
                      <div className="col-md-3 mb-3">
                        <img src={imageURL} style={{ width: '60%' }} />
                      </div>
                    </div>
                    
                  )}
                </div>
                <div className="col-md-3 mt-4 d-flex justify-content-end" style={{marginLeft:'6%', fontSize:'12px'}}>
                    {
                      !updateClicked ?
                      <div className="col-md-1 d-flex" style={{marginLeft:'-35%'}}>
                        <button type="submit" className="btn btn-success" onClick={handleSubmit} style={{fontSize:'16px'}}>
                            Save
                        </button>
                      </div>
                      
                        :
                        <>
                          <div className="col-md-2 d-flex justify-content-end" style={{marginLeft:'7%'}}>
                            <button type="submit" className="btn btn-danger" onClick={handleCancel}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-4 d-flex justify-content-end">
                            <button type="submit" className="btn btn-success" onClick={handleUpdate}>
                              Update
                            </button>
                          </div>
                        </>
                    }
                </div>
                </>
                ):""}

                <div className="row mt-3">
                    <div className="col-md-2 mb-3">
                    </div>
                </div>

                {/* Fourth Row*/}
                {lgShow1 && <>
                    <div className="row mt-3">
                    <div className="col-md-11">
                        <h5 className="mb-3" style={{fontSize:'22px'}}><b>Existing Competitor(s)</b></h5>
                        <table className="table" >
                        <thead>
                            <tr style={{textAlign:'center'}}>
                            <th style={{backgroundColor:'orange'}} scope="col">Competitor Image</th>
                            {/* <th scope="col">Car Class</th> */}
                            <th style={{backgroundColor:'orange'}} scope="col">Competitor Name</th>
                            <th style={{backgroundColor:'orange'}} scope="col">Competitor Code</th>
                            <th style={{backgroundColor:'orange'}} scope="col">Updated On</th>
                            <th style={{backgroundColor:'orange'}} scope="col">Updated By</th>
                            <th style={{backgroundColor:'orange'}} scope="col">Edit</th>
                            {/* <th style={{backgroundColor:'orange'}} scope="col">Download Image</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                            competitorDetail.length > 0 ? competitorDetail.map((val, i) => (
                                <tr style={{textAlign:'center'}}>
                                <td style={{ width: "200px" }}><img src={baseURL + "/static/uploads/" + val.image_path} style={{ width: '80px', height: '30px', }} /></td>
                                {/* <td>{val.SIPP_code}</td> */}
                                <td>{val.competitor_name}</td>
                                <td>{val.competitor_code}</td>
                                <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                                <td>{val.updated_by}</td>
                                <td>
                                <span
                                  style={{ cursor: accessType === "Edit" ? "not-allowed" : "pointer", pointerEvents: accessType === "View" ? "none" : "auto" }}
                                  onClick={() => { 
                                    if (accessType !== "View") {
                                      updateCompetitor(val); 
                                    }
                                  }}
                                >
                                  <EditIcon />
                                </span>
                              </td>
                                {/* <td><span style={{ cursor: "pointer" }}  disabled={accessType === "Edit"} onClick={() => { updateCompetitor(val); }}><EditIcon /></span></td> */}
                                {/* <td align="center"><span style={{ cursor: "pointer" }} onClick={() => { downloadImage(baseURL + "/static/img/" + val.image_path, val.image_path); }}><DownloadIcon /></span></td> */}

                                </tr>

                            )) : <span>No Competitors Available</span>
                            }

                        </tbody>
                        </table>
                    </div>
                    </div>{/* Fourth row ends */}
                </>
                }
                <div className="col-md-10 mb-4 ">

                </div>

                
            </div>
        </div> {/* overall wrapper row ends */}
</>
  );
}