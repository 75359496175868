import React, { useEffect, useState } from 'react';
import './Navibar.css'
import logo from './RMPlogo1.png'
import { IoLogOut } from "react-icons/io5";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../pages/backend_url';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function Navibar({ setSidebarColor, sidebarColor, setLoginSuccess }) {

  const [navibar, setNavibar] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  
  const [notiCount, setNotiCount] = useState(0)
  const [currentURL, setCurrentURL] = useState("")
  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);
  // useEffect(() => {
  //   if (foundUser.role === 'SuperAdmin') {
  //     const getEnreadMsgCount = () => {
  //       axios.get(baseURL + "/combined_unread_msg_count/" + foundUser.userEmail)
  //         .then((res) => {
  //           setNotiCount(res.data);
  //         })
  //     }
  //     // Set up an interval to run the function every 10 seconds
  //     const intervalId = setInterval(getEnreadMsgCount, 5000);
  //     // Clean up the interval when the component is unmounted
  //     return () => clearInterval(intervalId);
  //   }
  //   else if (foundUser.role != 'SuperAdmin') {
  //     const getEnreadMsgCount = () => {
  //       axios.get(baseURL + "/combined_unread_msg_count/" + foundUser.userEmail)
  //         .then((res) => {
  //           setNotiCount(res.data);
  //         })
  //     }
  //     // Set up an interval to run the function every 120 seconds
  //     const intervalId = setInterval(getEnreadMsgCount, 120000);
  //     // Clean up the interval when the component is unmounted
  //     return () => clearInterval(intervalId);
  //   }
  // }, [])
  function notificationsLabel(count) {
    if (count == 0) {
      return 'no notifications';
    }
    if (count > 100) {
      return 'more than 100 notifications';
    }
    return `${count} notifications`;
  }
  // return (
  //   <nav
  //     className={`navbar ${!navibar && 'navibar'} ${navibar && 'navibar2'}`}
  //     style={{
  //       backgroundColor: backgroundColor === "#54ADD1" ? "#54ADD1" : "#54ADD1",
  //       width: "15%", // Restrict width to 15%
  //       height: "100vh", // Full viewport height
  //       position: "fixed", // Fix it to the side of the screen
  //       left: 0, // Align to the left of the screen
  //       top: 0, // Align to the top of the screen
  //       display: "flex", // Flexible layout
  //       flexDirection: "column", // Stack content vertically
  //       padding: "10px 0", // Add padding only to top and bottom
  //       boxSizing: "border-box", // Include padding in width calculation
  //       overflow: "hidden", // Prevent overflowing content
  //     }}
  //   >
  //     <div
  //       className="container-fluid"
  //       style={{
  //         height: "100%", // Fill parent height
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "flex-start", // Align content to the top
  //         gap: "10px", // Add spacing between elements
  //       }}
  //     >
  //       {/* Logo and Title with AccountPopover on the same row */}
  //       <div
  //         className="row"
  //         style={{
  //           width: "100%",
  //           display: "flex", // Use flexbox layout
  //           alignItems: "center", // Vertically center the items
  //           justifyContent: "space-between", // Space out the elements to the left and right
  //           margin: 0, // Remove default margin
  //           padding: 0, // Remove default padding
  //         }}
  //       >
  //         <Link
  //           to="/"
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             gap: "8px", // Space between logo and text
  //             textDecoration: "none",
  //           }}
  //         >
  //           <img
  //             src={logo}
  //             style={{
  //               width: "40px",
  //               height: "auto",
  //               marginLeft: "-7%", // Remove any additional margin
  //             }}
  //             alt="Logo"
  //             className="logo"
  //           />
  //           <span
  //             style={{
  //               color: "black",
  //               fontSize: "16px",
  //               fontWeight: "bold",
  //               whiteSpace: "nowrap", // Prevent wrapping
  //               overflow: "hidden", // Hide overflowing text
  //               textOverflow: "ellipsis", // Add ellipsis if truncated
  //               maxWidth: "100%", // Prevent content from exceeding container
  //             }}
  //           >
  //             Rate Mate Pro
  //           </span>
  //         </Link>
  
  //         {/* Account Popover */}
  //         <div>
  //           <AccountPopover setLoginSuccess={setLoginSuccess} />
  //         </div>
  //       </div>
  //     </div>
  //   </nav>
  // );
  
  

  return (
    <nav className={`navbar ${!navibar && 'navibar'} ${navibar && 'navibar2'}`} 
    style={{
      backgroundColor: backgroundColor === "#54ADD1" ? "#54ADD1" : "#54ADD1",
    }}
    >
    
      <div className="container-fluid ">

        <div className="row" style={{ width: '100%' }}>
          <div className="col-auto" >
          <Link to="/">  <img src={logo} style = {{marginLeft:'-2px', width: '50px', height: 'auto'}}  alt="" className='logo' /> 
          <span className="ms-1" style={{color:'black', fontSize:'25px', marginLeft:'-5px', }}><b> Rate Mate Pro</b></span>
          </Link>
          </div>
          <div className="col d-flex justify-content-end">
            <AccountPopover setLoginSuccess={setLoginSuccess} />
          </div>
        </div>

      </div>
    </nav>
  )
}
