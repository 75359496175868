import React, { useState, useEffect, useRef } from "react";
import { Button, Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import './HotDates.css'
import Chart1 from "./Chart1";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// export default function HotDates() {
export default function UpdateForm({ accessType }) {    

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [location, setLocation] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [lor, setLor] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // const [responseData, setResponseData] = useState({});
    const [loading, setLoading] = useState(false);
    const [rateSource, setRateSource] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [sippCodeOption, setSippCodeOption] = useState([]);
    const [selectedSippCodes, setSelectedSippCodes] = useState([]);
    const [competitorOption, setCompetitorOption] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    // const [selectedCompetitor, setSelectedCompetitor] = useState('');
    const [companyName, setCompanyName] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);

    const [chartdata1, setResponseData] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
    
        if (foundUser.role === 'SuperAdmin') {
    
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['CompetitorSetUp_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
    
                    setcompanyDetail(data);
                });
        }
      }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setLocationName('')
            setLocationId('')
            setLocationDetail([{}])
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
    
            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
        }
      }, [companyName]);


    // useEffect(() => {
    //     const loggedInUser = sessionStorage.getItem("user_token");
    //     const foundUser = JSON.parse(loggedInUser);
    //     setLoggedInUserDetail(foundUser);
        
    //     const requestOptions2 = {
    //     method: "POST",
    //     mode: 'cors',
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     };
    //     axios.get(baseURL + "/fetch_all_locations")
    //     .then((res) => {
    //         // console.log(res)
    //         setLocationDetail(res.data); 
    //     });
    // }, []);

    const lorOptions = Array.from({ length: 7 }, (v, k) => ({
        value: k + 1,
        label: k + 1
    }));

    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    // const handleInputChangeLocationName = (characterEntered) => {
    //     setLocationName(characterEntered.value);
    // };
    const rateSourceOptions = [
        { value: 'EXI', label: 'Expedia' },
        { value: 'PRC', label: 'Priceline' }
    ];

    // const locationOptions = locationDetail.map((value) => (
    //     { value: value.airport_code, label: value.airport_code }
    // ));
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
      ));

    const pickupTimeOptions = [
        { value: '10:00', label: '10:00 AM' },
        { value: '15:00', label: '03:00 PM' }
    ];

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
        setLocation(characterEntered.value);
        setMinDate('')
        setMaxDate('')
        setCompetitorOption([])
        setSelectedCompetitor('')
        setSippCodeOption([])
        setSelectedSippCodes([])
        fetch(baseURL + "/fetch_fleet_by_location/" + characterEntered.value)
        .then(res => res.json())
        .then(data => {
            if (data.length > 0) {
                let tempArr = [];
                let tempObj = {}
                let temp_car_name = [];
                let temp_arr = []

                for (let i = 0; i < data.length; i++) {
                    if (data[i] !== undefined && temp_car_name.indexOf(data[i].SIPP_CODE) < 0) {
                        temp_car_name.push(data[i].SIPP_CODE);
                        temp_arr.push(data[i]);
                    }
                }
                data = temp_arr;
                for (let i = 0; i < data.length; i = i + 4) {
                    let tempArr2 = []
                    for (let j = i; j < i + 4; j++) {
                      if (data[j] !== undefined) {
                        tempArr2.push(data[j].SIPP_CODE);
                        tempObj[data[j].SIPP_CODE] = false;
                      }
                    }
                    tempArr.push(tempArr2);
                }
          
                const options = data.map((value) => ({
                    value: value.SIPP_CODE,
                    label: value.SIPP_CODE,
                }));
                const allOption = { value: 'ALL', label: 'Select All' };
                const optionsWithAll = [allOption, ...options];
                setSippCodeOption(optionsWithAll);
            }
            else
            {
                setSippCodeOption([])
                setSelectedSippCodes([])
            }
        })

        fetch(baseURL + "/fetch_from_to_date/" + characterEntered.value)
        .then(res => res.json())
        .then(data => {
            setMinDate(new Date(data.minDate));
            setMaxDate(new Date(data.maxDate));
        })

        fetch(baseURL + "/fetch_competitor_by_location/" + characterEntered.value)
        .then(res => res.json())
        .then(data => {
            const options = data.map((value) => ({
                value: value,
                label: value,
            }));
            const allOption = { value: 'ALL', label: 'Select All' };
            const optionsWithAll = [allOption, ...options];
            setCompetitorOption(optionsWithAll);

            // const allOption = { value: 'ALL', label: 'Select All' };
            //     const optionsWithAll = [allOption, ...options];
            //     setSippCodeOption(optionsWithAll);
        })
    };

    const handleInputChangeLor = e => {
        setLor(e.value)
    }

    const handleInputChangeRateSource = (selectedOption) => {
        setRateSource(selectedOption.value);
    };

    const handleInputChangePickupTime = (selectedOption) => {
        setPickupTime(selectedOption.value);
    };

    const handleInputChangeSippCode = (selectedOptions) => {
        if (selectedOptions) {
            if (selectedOptions.some(option => option.value === 'ALL')) {
                // If "ALL" is selected, set all options except "ALL"
                setSelectedSippCodes(sippCodeOption.filter(option => option.value !== 'ALL'));
            } else {
                setSelectedSippCodes(selectedOptions);
            }
        } else {
            setSelectedSippCodes([]);
        }
    };

    const handleInputChangeCompetitor = (selectedOptions) => {
        if (selectedOptions) {
            if (selectedOptions.some(option => option.value === 'ALL')) {
                // If "ALL" is selected, set all options except "ALL"
                setSelectedCompetitor(competitorOption.filter(option => option.value !== 'ALL'));
            } else {
                setSelectedCompetitor(selectedOptions);
            }
        } else {
            setSelectedCompetitor([]);
        }
    };

    // const handleInputChangeCompetitor = (selectedOption) => {
    //     setSelectedCompetitor(selectedOption)
    // }

    const CustomLoader = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
                <h2 style={{ color: '#fff', marginBottom: '20px' }}>Fetching hot dates of next 6 months....</h2>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                </div>

                {/* Second set of images (right to left) */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                </div>
                {/* Animation styles */}
                <style>
                    {`
                        @keyframes moveLeftToRight {
                            0% {
                                transform: translateX(-100%);
                            }
                            100% {
                                transform: translateX(100%);
                            }
                        }

                        @keyframes moveRightToLeft {
                            0% {
                                transform: translateX(100%);
                            }
                            100% {
                                transform: translateX(-100%);
                            }
                        }
                    `}
                </style>
            </div>
        );
    };

    const toLocalISOString = (date) => {
        const tzOffset = -date.getTimezoneOffset();
        const diff = tzOffset >= 0 ? '+' : '-';
        const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
        return date.getFullYear() +
          '-' + pad(date.getMonth() + 1) +
          '-' + pad(date.getDate()) +
          'T' + pad(date.getHours()) +
          ':' + pad(date.getMinutes()) +
          ':' + pad(date.getSeconds()) +
          diff + pad(tzOffset / 60) +
          ':' + pad(tzOffset % 60);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (location === "") {
            Swal.fire('Please select location');
            return;
        }
        if (lor === "") {
            Swal.fire('Please select LOR to proceed');
            return;
        }
        if (pickupTime === "") {
            Swal.fire('Please select Pickup Time to proceed');
            return;
        }
        if (rateSource === "") {
            Swal.fire('Please select Rate Source to proceed');
            return;
        }
        if (selectedSippCodes.length === 0)
        {
            Swal.fire('Please select atleast one car class to proceed');
            return;
        }
        if (selectedCompetitor.length === 0)
        {
            Swal.fire('Please select atleast one competitor to proceed');
            return;
        }
        const competitorCodes = selectedCompetitor.map(item => item.value.split(' ')[0]);
        // console.log('selectedCompetitor',selectedCompetitor)
        // console.log('selectedSippCodes',selectedSippCodes)
        // var competitorCode = ''
        // if(selectedCompetitor['value'] === 'ALL')
        // {
        //     competitorCode = 'all'
        // }
        // else
        // {
        //     const text = selectedCompetitor['value']
        //     competitorCode = text.split(' ')[0]
        // }
        const formattedStartDate = startDate ? toLocalISOString(startDate) : null;
        const formattedEndDate = endDate ? toLocalISOString(endDate) : null;

        const body_payload =
        {
            location: location,
            lor: lor,
            rateSource : rateSource,
            pickupTime : pickupTime,
            sippCode: selectedSippCodes,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            competitorCode : competitorCodes
        }
        setLoading(true);
        axios.post(baseURL + "/fetch_hotdates_data", body_payload)
        .then((res) => {
            if(res.data && Object.keys(res.data).length > 0)
            {
                setLoading(false);
                setResponseData(res.data);
                // console.log(typeof res.data)
            }
            else
            {
                setLoading(false);
                Swal.fire('No Records Found')
                setResponseData("")
            }
            
        });
    }

    // const carClasses = [...new Set(Object.values(responseData).flatMap(monthData =>
    //     Object.keys(monthData).flatMap(hotDateKey =>
    //       Object.keys(monthData[hotDateKey])
    //     )
    // ))].sort();

    // const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // const sortedMonths = Object.keys(responseData).sort((a, b) => monthNames.indexOf(a) - monthNames.indexOf(b));
    
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        // control: (provided) => ({
        //     ...provided,
        //     width: '210px', // Set your desired width here
        // }),
    };

    const customStyles2 = {
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        control: (provided) => ({
            ...provided,
            maxWidth: '450px', // Set your desired width here
        }),
    }

    const transformData = (data) => {
        const transformed = {};
    
        for (const carClass in data) {
            transformed[carClass] = data[carClass].map(item => ({
                name: item.pickup_date,
                Average: item.this_average,
                Rate : item.total,
                pickupDate : item.pickup_date,
                locationName: item.location_name,
                locationID: item.location_id,
                carClass : item.car_class,
                competitor: item.competitor_code,
                rateSource : item.rate_source,
                pickupTime : item.pickup_time,
                companyID: item.company_id,
                companyName: item.company_name,
                lor: item.lor
            }));
        }
    
        return transformed;
    };
    const chartData = transformData(chartdata1);
    const carClasses = Object.keys(chartData);

    const renderRows = () => {
        const rows = [];
        for (let i = 0; i < carClasses.length; i += 2) {
            rows.push(
                <div className="row" key={i}>
                    <div className="col-6 mt-2">
                        <Chart1
                            title={carClasses[i]}
                            aspect={2 / 1}
                            chartdata1={chartData[carClasses[i]]}
                        />
                    </div>
                    {carClasses[i + 1] && (
                        <div className="col-6 mt-2">
                            <Chart1
                                title={carClasses[i + 1]}
                                aspect={2 / 1}
                                chartdata1={chartData[carClasses[i + 1]]}
                            />
                        </div>
                    )}
                </div>
            );
        }
        return rows;
    };

    return (
        <>
            <div className="row pt-1">
                <div className="col-md-10 mx-3">
                <h5>Hot Dates</h5>
                </div>
                <form style={{ fontSize: '14px', color: 'black' }}>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-1 mb-1">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5}}>
                                Account Name
                            </label>
                        </div>
                        <div className="col-md-3 mb-3" >
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                        {/* <div className="col-md-1 mb-1">
                            <label htmlFor="location" className="form-label" style={{ marginTop: 5 }}>
                                Location
                            </label>
                        </div> */}
                        <div className="col-md-1 mb-1">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>
                        <div className="col-md-3 mb-3" >
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>
                        {/* <div className="col-md-3 mb-3" >
                            <Select
                                value={{ value: location, label: (location ? location : "Select Location") }}
                                inputId="location"
                                name="location"
                                options={locationOptions}
                                onChange={handleInputChangeLocation}
                                styles={customStyles}
                            />
                        </div> */}
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                Rate Source
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={rateSourceOptions.find(option => option.value === rateSource)}
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.value}
                                inputId="rateSource"
                                name="rateSource"
                                options={rateSourceOptions}
                                onChange={handleInputChangeRateSource}
                                placeholder="Select Rate Source"
                                styles={customStyles}
                            />
                        </div>
                    </div>
                    <div className="row pt-2 mx-2" >
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                Pickup Time
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={pickupTimeOptions.find(option => option.value === pickupTime)}
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.value}
                                inputId="pickupTime"
                                name="pickupTime"
                                options={pickupTimeOptions}
                                onChange={handleInputChangePickupTime}
                                placeholder="Select Pickup Time"
                                styles={customStyles}
                            />
                        </div>
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                From Date
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <DatePicker
                                id="fromDate"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={minDate}
                                maxDate={maxDate}
                                dateFormat="yyyy/MM/dd"
                                className={`form-control ${!minDate || !maxDate ? 'disabled-datepicker' : ''}`}
                            />
                        </div>
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                To Date
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <DatePicker
                                id="toDate"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                minDate={minDate}
                                maxDate={maxDate}
                                dateFormat="yyyy/MM/dd"
                                className={`form-control ${!minDate || !maxDate ? 'disabled-datepicker' : ''}`}
                            />
                        </div>
                    </div>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                LOR
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={{ value: lor, label: (lor ? lor : "Select LOR") }}
                                inputId="lor"
                                name="lor"
                                options={lorOptions}
                                onChange={handleInputChangeLor}
                                styles={customStyles}
                            />
                        </div>
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                Sipp Code
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={selectedSippCodes}
                                inputId="sippCode"
                                name="sippCode"
                                options={sippCodeOption}
                                onChange={handleInputChangeSippCode}
                                isMulti // Enable multi-select
                                closeMenuOnSelect={false} // Optional: keeps the menu open after selecting an option
                                styles={customStyles2}
                                placeholder="Select Sipp Code"
                            />
                        </div> 
                        <div className="col-md-1 mb-1">
                            <label htmlFor="lor" className="form-label" style={{ marginTop: 5 }}>
                                Competitor
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={selectedCompetitor}
                                inputId="competitor"
                                name="competitor"
                                options={competitorOption}
                                isMulti
                                onChange={handleInputChangeCompetitor}
                                closeMenuOnSelect={false} // Optional: keeps the menu open after selecting an option
                                styles={customStyles2}
                                placeholder="Select Competitor"
                            />
                        </div> 
                    </div>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-1 mb-3">
                        </div>
                        {/* <div className="col-md-2 mb-3">
                        </div> */}
                        {loading && <CustomLoader />}
                        <div className="col-md-3 mb-3">
                            <button style={{ width: '143px', fontSize: '12px' }} type="submit" disabled={buttonDisabled} 
                            className="btn btn-success" onClick={handleSubmit} >Submit</button>
                        </div>
                    </div>
                </form>
                <div className="charts col-12 d-flex gap-5">
                    <div className="charts col-12">
                        {renderRows()}
                    </div>
                </div>
            </div>
        </>
    ); 
  
}