import "./HotDates.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}-${year}`;
};

// Function to preprocess and format data for a grouped chart
const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Rate: item.Rate !== undefined && item.Rate !== null ? item.Rate : 0, // Default to 0 if Rate is missing
  }));
};

const Chart1 = ({ aspect, title, chartdata1 }) => {
  const axisTickStyle = {
    fontSize: 13,
    fontWeight: "bold",
    fill: "black",
  };
  const tooltipContentStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "13px",
    fontWeight: "bold",
    color: "black",
  };

  const tooltipLabelFormatter = (label) => formatDate(label);

  // Preprocess data
  const processedData = preprocessData(chartdata1);

  return (
    <div className="chart">
      <div className="title" style={{ color: "black", fontWeight: "bold" }}>
        {"SIPP Codes - " + title}
      </div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <BarChart
          width={730}
          height={250}
          data={processedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <XAxis
            dataKey="name"
            stroke="gray"
            tickFormatter={formatDate}
            tick={axisTickStyle}
          />
          <YAxis
            stroke="gray"
            tick={axisTickStyle}
            tickFormatter={(value) => `$${value}`}
          />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip
            contentStyle={tooltipContentStyle}
            labelFormatter={tooltipLabelFormatter}
            formatter={(value) => `$${value}`}
          />
          <Legend />
          {/* Bars for each SIPP Code */}
          <Bar
            dataKey="Average"
            name="Competitors Average"
            fill="#309FEB"
            barSize={20}
          />
          <Bar
            dataKey="Rate"
            name="Our Price"
            fill="#FEB444"
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart1;
