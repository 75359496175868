import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navibar from './components/layout/Navibar';
import NavibarCollasped from './components/layout/NavibarCollasped';
import Sidebar from './components/layout/Sidebar';
import AccountSetup from './components/pages/account/Account'
import UserSetup from './components/pages/user/User'
import Dashboard from './components/pages/dashboard/Dashboard'
import Locations from './components/pages/locations/Locations'
import AccountLocations from './components/pages/accountLocations/AccountLocations'
import TaxSetup from './components/pages/taxSetup/TaxSetup'
import FleetSetUp from "./components/pages/fleetSetUp/FleetSetUp"
import AccountRateCodeSetup from "./components/pages/accountRateCodeSetup/AccountRateCodeSetup"
import RateCodeSetup from "./components/pages/rateCodeSetup/RateCodeSetup"
import InstantAutomationPro from './components/pages/instantAutomationPro/InstantAutomationPro';
import InstantRateShop from './components/pages/instantRateShop/instantRateShop';
import AutomationPlans from './components/pages/automationPlans/AutomationPlans';
import Login from "./components/pages/login/Login";
import RolesSetup from "./components/pages/rolesSetup/RolesSetup";
import Cars from "./components/pages/cars/Cars";
import Reports from "./components/pages/reports/Reports";
import EventLog from './components/pages/eventLog/EventLog';
import FleetStandard from './components/pages/fleetStandard/FleetStandard';
import RateCodeMapping from './components/pages/rateCodeMapping/RateCodeMapping';
// import RateSnapshot from './components/pages/rateSnapshot/RateSnapshot';
// import OptionalServicesImages from './components/pages/optionalServicesImages/OptionalServicesImages';
import MyProfile from './components/pages/my_profile/MyProfile';
import CompetitorSetup from './components/pages/competitorSetup/CompetitorSetup';
import MinimumRateMapping from './components/pages/minimumRateMapping/MinimumRateMapping';
import EnableAutomation from './components/pages/enableAutomation/EnableAutomation';
import AutomationScheduler from './components/pages/automationSetUp/AutomationSetUp';

import CurrencyConverter from './components/pages/currencyConverter/CurrencyConverter';
import ReportSetUp from './components/pages/reportSetUp/ReportSetUp';
import RateDownload from './components/pages/rateDownload/RateDownload';
import Competitors from './components/pages/competitors/Competitors';

import MILocation from './components/pages/marketInsights/Location';
import MIHotDates from './components/pages/marketInsights/HotDates';
import CustomStrategy from './components/pages/strategy/Strategy';


import { baseURL } from './components/pages/backend_url';
import axios from 'axios';

function App() {

  const [collaspedFlag, setCollapsedFlag] = useState(false);
  const [sidebarColor, setSidebarColor] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [moduleDetail, setModuleDetail] = useState([]);
  const [moduleDetailHash, setModuleDetailHash] = useState({});
  const [moduleDetailHash2, setModuleDetailHash2] = useState({});
  const [subModuleData, setSubModuleData] = useState([{}]);



  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    // const foundUser = 'SuperAdmin'

    axios.get(baseURL + "/get_modules")
      .then((res) => {

        const loggedInUser1 = sessionStorage.getItem("user_token");
        if (loggedInUser1) {

          setLoginSuccess(true);
        }

        let temp_hash = {};
        let temp_hash2 = {};
        // let foundUser = 'SuperAdmin'

        // if (foundUser?.role === 'Users') {
        //   res.data.push({
        //     "name": "MyProfile",
        //     "favourite": {
        //     }
        //   })
        // }
        // if (foundUser.role === 'Users') {
        //   res.data.push({
        //     "name": "MyProfile",
        //     "favourite": {
        //     }
        //   })
        // }        
        
        const temp_module_arr = res.data.map((val) => {
          let temp_mod_names = [];
          let subModuleExists = val.hasOwnProperty('sub_module');
          let sub_modules = subModuleExists ? val.sub_module : null;

          let temp_mod_name = val.name.toString().trim();
          if (temp_mod_name.indexOf(' ') > -1) {
              let t1_split = temp_mod_name.split(" ");
              if (t1_split.length > 0) {
                  temp_mod_name = '';
                  for (let i1 = 0; i1 < t1_split.length; i1++) {
                      temp_mod_name += t1_split[i1];
                  }
              }
          }
          if (sub_modules !== null && sub_modules.length > 0) {
            for (let i = 0; i < sub_modules.length; i++) {
              let sub_module = sub_modules[i].routing;
              if (sub_module.indexOf(' ') > -1) {
                let t1_split = sub_module.split(" ");
                if (t1_split.length > 0) {
                    sub_module = '';
                    for (let i1 = 0; i1 < t1_split.length; i1++) {
                      sub_module += t1_split[i1];
                    }
                }
              }
              temp_mod_names.push(sub_module.toString().trim());
            }
          }
          temp_mod_names.push(temp_mod_name);
          
          if (temp_mod_names.length > 1) {
            temp_mod_names.forEach((temp_mod_name, index) => {
                if (temp_mod_name === 'Min/MaxSettings')
                  temp_hash2[temp_mod_name] = <MinimumRateMapping />
                if (temp_mod_name === 'fleet_setup')
                  temp_hash2[temp_mod_name] = <FleetSetUp />
                if (temp_mod_name === 'fees_taxes')
                  temp_hash2[temp_mod_name] = <TaxSetup />
                else if (temp_mod_name === 'competitor_setup')
                  temp_hash2[temp_mod_name] = <CompetitorSetup />
                else if (temp_mod_name === 'Account')
                  temp_hash2[temp_mod_name] = <AccountSetup />
                else if (temp_mod_name === 'locations')
                  temp_hash2[temp_mod_name] = <Locations/>
                else if (temp_mod_name === 'AccountLocations')
                  temp_hash2[temp_mod_name] = <AccountLocations/>  
                else if (temp_mod_name === 'competitors')
                  temp_hash2[temp_mod_name] = <Competitors />
                else if (temp_mod_name === 'fleet_standard')
                  temp_hash2[temp_mod_name] = <FleetStandard />
                else if (temp_mod_name === 'automation_plan')
                  temp_hash2[temp_mod_name] = <AutomationPlans />
                else if (temp_mod_name === 'user')
                  temp_hash2[temp_mod_name] = <UserSetup />
                else if (temp_mod_name === 'cars')
                  temp_hash2[temp_mod_name] = <Cars />  
                else if (temp_mod_name === 'InstantAutomationPro')
                temp_hash2[temp_mod_name] = <InstantAutomationPro />  
                else if (temp_mod_name === 'InstantRateShop')
                temp_hash2[temp_mod_name] = <InstantRateShop/>  
                else if (temp_mod_name === 'AutomationScheduler')
                temp_hash2[temp_mod_name] = <AutomationScheduler />
                else if (temp_mod_name === 'report_setup')
                temp_hash2[temp_mod_name] = <ReportSetUp />
                else if (temp_mod_name === 'rate_download')
                temp_hash2[temp_mod_name] = <RateDownload />
            });
          } else {
              temp_mod_names.forEach(temp_mod_name => {  
                if (temp_mod_name === 'Account')
                temp_hash[temp_mod_name] = <AccountSetup />
                if (temp_mod_name === 'Min/MaxSettings')
                  temp_hash[temp_mod_name] = <MinimumRateMapping />
                if (temp_mod_name === 'FeesandTaxes')
                  temp_hash[temp_mod_name] = <TaxSetup />
                else if (temp_mod_name === 'CompetitorSetUp')
                  temp_hash[temp_mod_name] = <CompetitorSetup />
                else if (temp_mod_name === 'AccountLocations')
                  temp_hash[temp_mod_name] = <AccountLocations />  
                else if (temp_mod_name === 'Account')
                  temp_hash[temp_mod_name] = <AccountSetup />  
                else if (temp_mod_name === 'Locations')
                  temp_hash[temp_mod_name] = <Locations/>
                else if (temp_mod_name === 'Competitors')
                  temp_hash[temp_mod_name] = <Competitors />  
                else if (temp_mod_name === 'FleetStandard')
                  temp_hash[temp_mod_name] = <FleetStandard />
                if (temp_mod_name === 'FleetSetUp')
                  temp_hash[temp_mod_name] = <FleetSetUp />
                else if (temp_mod_name === 'AutomationPlan')
                  temp_hash[temp_mod_name] = <AutomationPlans /> 
                else if (temp_mod_name === 'User')
                  temp_hash[temp_mod_name] = <UserSetup /> 
                else if (temp_mod_name === 'InstantAutomationPro')
                  temp_hash[temp_mod_name] = <InstantAutomationPro />  
                else if (temp_mod_name === 'InstantRateShop')
                  temp_hash[temp_mod_name] = <InstantRateShop/>  
                else if (temp_mod_name === 'AutomationScheduler')
                  temp_hash[temp_mod_name] = <AutomationScheduler />
                else if (temp_mod_name === 'Tax')
                  temp_hash[temp_mod_name] = <TaxSetup />
                else if (temp_mod_name === 'AccountRateCode')
                  temp_hash[temp_mod_name] = <AccountRateCodeSetup />
                else if (temp_mod_name === 'RateCode')
                  temp_hash[temp_mod_name] = <RateCodeSetup />
      
                else if (temp_mod_name === 'Min-MaxSettings')
                  temp_hash[temp_mod_name] = <MinimumRateMapping />
      
                else if (temp_mod_name === 'InstantAutomationPro')
                  temp_hash[temp_mod_name] = <InstantAutomationPro />
                else if (temp_mod_name === 'AccessSetup')
                  temp_hash[temp_mod_name] = <RolesSetup />
                else if (temp_mod_name === 'Cars')
                  temp_hash[temp_mod_name] = <Cars />
      
                else if (temp_mod_name === 'Reports')
                  temp_hash[temp_mod_name] = <Reports />      
                else if (temp_mod_name === 'EventLog')
                  temp_hash[temp_mod_name] = <EventLog />
      
                else if (temp_mod_name === 'MyProfile')
                  temp_hash[temp_mod_name] = <MyProfile />
                else if (temp_mod_name === 'CompetitorSetup')
                  temp_hash[temp_mod_name] = <CompetitorSetup />
                else if (temp_mod_name === 'EnableAutomation')
                  temp_hash[temp_mod_name] = <EnableAutomation />
                else if (temp_mod_name === 'AutomationPlans')
                temp_hash[temp_mod_name] = <AutomationPlans />  
      
                else if (temp_mod_name === 'RateCodeMapping')
                temp_hash[temp_mod_name] = <RateCodeMapping />
      
                else if (temp_mod_name === 'CurrencyConverter')
                temp_hash[temp_mod_name] = <CurrencyConverter />
      
                else if (temp_mod_name === 'ReportSetUp')
                temp_hash[temp_mod_name] = <ReportSetUp />
                else if (temp_mod_name === 'RateDownload')
                temp_hash[temp_mod_name] = <RateDownload />  
      
                // else if (temp_mod_name === 'Competitors')
                // temp_hash[temp_mod_name] = <Competitors />

                else if (temp_mod_name === 'MarketInsightLocation')
                temp_hash[temp_mod_name] = <MILocation />

                else if (temp_mod_name === 'RateInsights')
                temp_hash[temp_mod_name] = <MIHotDates />
              

                else if (temp_mod_name === 'CustomStrategy')
                temp_hash[temp_mod_name] = <CustomStrategy />
                
              });  
          }

          return temp_mod_names;

        })




  



        if (foundUser?.role === 'Users') {
        // if (foundUser  === 'Users4') {
          axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
            .then((res1) => {
              console.log('foundUser',foundUser)
              let accnt = foundUser.account_id
              if (foundUser.account_type.account_type != 'Corporate') {

                accnt = foundUser.account_type.affiliated_to_id
              }

              axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role, 'account_id': accnt.toString() })
              // axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role})
                .then((res2) => {
                  console.log("get_user_role_detail-res",res2)
                  res2.data[0].role_definition['MyProfile_Access Specifier'] = "Edit";
                  // res2.data[0].selected_access['MyProfile_Access Specifier'] = "Edit";


                  // const tempMenuItems = temp_module_arr.filter((val) => {
                  //   if ((val === 'SupportScreen') || (res2.data[0].selected_access[val + "_Access Specifier"] === "Edit"
                  //     || res2.data[0].selected_access[val + "_Access Specifier"] === "View")) {
                  //     return val
                  //   }
                  // })
                  const tempMenuItems = temp_module_arr.filter((val) => {
                    if ((val === 'SupportScreen') || (val === 'ViewBaseRates' && (res2.data[0].role_definition["RateUpdate_Access Specifier"] === "Edit"
                    || res2.data[0].role_definition["RateUpdate_Access Specifier"] === "View")) || (res2.data[0].role_definition[val + "_Access Specifier"] === "Edit"
                      || res2.data[0].role_definition[val + "_Access Specifier"] === "View")) {
                      return val
                    }
                  })

                  setModuleDetailHash(temp_hash);
                  // setModuleDetailHash2(temp_hash2);
                  setModuleDetail(tempMenuItems);

                })
            })
        }
        else if (foundUser?.role === 'SuperAdmin') {
        // else if (foundUser === 'SuperAdmin') {
          console.log('abcds')
          setModuleDetailHash(temp_hash);
          setModuleDetailHash2(temp_hash2);
          setModuleDetail(temp_module_arr);

        }
      })
      console.log('foundUser',foundUser)



  }, [])
  // console.log(moduleDetailHash2['MinMaxSettings'])
  // console.log("moduleDetailHash", moduleDetailHash)
  return (
    <>
      <Router>

        {!loginSuccess ? "" : (<>
          {collaspedFlag ? <NavibarCollasped setLoginSuccess={setLoginSuccess} /> : <Navibar setSidebarColor={setSidebarColor} sidebarColor={sidebarColor} setLoginSuccess={setLoginSuccess} />}

          <div className="customSidebar">
            <Sidebar setCollapsedFlag={setCollapsedFlag} sidebarColor={sidebarColor} />
          </div>
        </>)}
        <div className={!loginSuccess ? "" : collaspedFlag ? 'rightSideContentCollasped' : "rightSideContent"}>

          <Routes>
            {!loginSuccess ?

              <Route exact path="/" element={<Login setLoginSuccess={setLoginSuccess} />} />

              :
              (<>
                <Route exact path="/" element={<Dashboard />} />
                {
                  moduleDetail.length > 0 ? moduleDetail.map((val) => (<>
                    { val.length > 1 ? val.map((val2) => (
                      (<><Route exact path={"/"+val2} element={moduleDetailHash2[val2]} /></>)
                    ))
                    :
                    (<Route exact path={"/" + val} element={moduleDetailHash[val]} />)
                    }
                    </>
                  )) : ""
                }

              </>)}
          </Routes>
        </div>


      </Router>
    </>
  );
}

export default App;
